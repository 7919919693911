import { Grid, Typography, useTheme } from '@mui/material';
import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { createEditor, Descendant } from 'slate';
import { Slate, withReact, RenderElementProps } from 'slate-react';
import { useTranslation } from 'react-i18next';
import ChipPlaceholder from './ChipPlaceholder';
import CustomChip from './CustomChip';
import { getEditorValue, removeCurrentNode } from './slate.utils';
import { CustomEditable } from './CustomEditable';
import {
  getChipLabel,
  insertPlaceholderChip,
  replaceEnterWithNewLine,
  replacePlaceholdersWithChips,
  withPlaceholder,
} from './placeholder.utils';
import { Placeholder } from './PlaceholderInput.types';
import { withHistory } from 'slate-history';

interface Props {
  onChange: (v: string) => void;
  label: string;
  initialValue: string;
  placeholders: Placeholder[];
}

const PlaceholderInput: React.FC<Props> = ({ onChange, initialValue, placeholders, label }) => {
  const [editor] = useState(() => withPlaceholder(withHistory(withReact(createEditor()))));
  const { t } = useTranslation('placeholderInput');
  const theme = useTheme();
  const [isError, setIsError] = useState(false);

  const initValue: Descendant[] = [
    {
      type: 'root',
      children: [{ text: '' }],
    },
  ];

  const insertInitialValue = useCallback(() => {
    replacePlaceholdersWithChips(editor, initialValue);
    // clear history
    // because initial Value is empty to prevent undoing to empty textfield while user never saw it empty
    editor.history = { redos: [], undos: [] };
  }, [editor, initialValue]);

  useEffect(() => {
    insertInitialValue();
  }, [insertInitialValue]);

  const renderElement = useCallback(
    (props: RenderElementProps): JSX.Element => {
      const label = getChipLabel(props.element.children[0].text, placeholders);

      switch (props.element.type) {
        case 'placeholder':
          return (
            <div {...props.attributes} style={{ display: 'inline-block', userSelect: 'none', margin: '0px 2px' }} contentEditable={false}>
              <ChipPlaceholder label={label} tooltipTitle={t('tooltip', { label })} onDelete={removeCurrentNode(props.element, editor)} />
              {props.children}
            </div>
          );
        case 'span':
          return <span {...props.attributes}>{props.children}</span>;
        default:
          return (
            <div style={{ width: '100%', maxHeight: '100%', overflow: 'auto', padding: '10px 15px' }} {...props.attributes}>
              {props.children}
            </div>
          );
      }
    },
    [editor, placeholders, t],
  );

  const handleError = useCallback(() => {
    const value = getEditorValue(editor);
    return value.length > 1000 ? setIsError(true) : setIsError(false);
  }, [editor]);

  const handleOnChange = useCallback(() => {
    onChange(getEditorValue(editor));
    handleError();
  }, [editor, handleError, onChange]);

  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      replaceEnterWithNewLine(editor, e);
    },
    [editor],
  );

  return (
    <>
      <Grid container mb={0.5}>
        <Grid item xs={6}>
          <Typography color={theme.palette.text.secondary} variant="h3" gutterBottom={isError}>
            {label}
          </Typography>
          {isError && (
            <Typography color={theme.palette.error.main} variant="body2">
              {t('benefitsModule:GIFTS.placeHolderInput.errorMessage')}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end" gap={1}>
          {placeholders.map((p, i) => (
            <CustomChip key={i} label={p.label} onClick={insertPlaceholderChip(editor, p.value)} />
          ))}
        </Grid>
      </Grid>
      <Slate editor={editor} initialValue={initValue} onChange={handleOnChange}>
        <CustomEditable onKeyDown={handleOnKeyDown} renderElement={renderElement} error={isError} />
      </Slate>
    </>
  );
};

export default PlaceholderInput;
