import PeopleIcon from '@mui/icons-material/People';
import { UserDTOStatusEnum } from 'probonio-shared-ui/api';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../redux/hooks';
import { useEmployeeList } from '../../../userManagement/useEmployeeList';
import { TutorialStepDefinition } from '../TutorialStep';

export function useInviteEmployeeStep(): TutorialStepDefinition {
  const { t } = useTranslation('tutorialModule');
  const tenantId = useAppSelector(state => state.me.currentTenantId);
  const [queryEnabled, setQueryEnabled] = useState(true);
  const { data: pendingEmployeeList } = useEmployeeList(
    { page: 0, pageSize: 0, userStatus: UserDTOStatusEnum.Pending },
    { enabled: queryEnabled },
  );
  const { data: activeEmployeeList } = useEmployeeList(
    { page: 0, pageSize: 0, userStatus: UserDTOStatusEnum.Active },
    { enabled: queryEnabled },
  );

  const loading = !pendingEmployeeList || !activeEmployeeList;
  const completed = pendingEmployeeList && activeEmployeeList && (pendingEmployeeList.totalCount > 0 || activeEmployeeList.totalCount > 1);

  useEffect(() => {
    if (completed) {
      setQueryEnabled(false);
    }
  }, [completed]);
  useEffect(() => {
    if (tenantId) {
      setQueryEnabled(true);
    }
  }, [tenantId]);

  return useMemo(
    () => ({
      ...t('steps.inviteEmployee', { returnObjects: true }),
      path: '/employees',
      buttonIcon: PeopleIcon,
      loading,
      completed,
    }),
    [completed, loading, t],
  );
}
