import { LinearProgress } from '@mui/material';
import { AxiosError } from 'axios';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BreadcrumbLink } from '../../module/navigation';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';
import { EmployeeNotFound } from '../../module/userManagement/EmployeeNotFound';
import UserProfileSubPage from '../../module/userManagement/UserProfile/subpages/UserProfileSubPage';
import { renderUserName } from '../../module/userManagement/userFormatter';
import { useNavigateBack } from '../../util/useNavigateBack';
import { useNavigateWithPrevPath } from '../../util/useNavigateWithPrevPath';
import { useScrollToTop } from '../../util/useScrollToTop';

interface Props {
  title?: string;
  isRoot?: boolean;
  editActive?: boolean;
}

export const BaseEmployeePage: React.FC<React.PropsWithChildren<Props>> = ({ title, isRoot, editActive, children }) => {
  const { t } = useTranslation('usersModule');
  const withMessage = useWithMessage();
  const { employeeId } = useParams();
  const navigate = useNavigateWithPrevPath();

  useScrollToTop();

  const {
    data: employee,
    isLoading,
    error,
  } = useTenantQuery(
    ['employees', employeeId, { includeRegistrationToken: true }, apis.employee.getEmployeeById.name],
    async tenantId => {
      if (!employeeId) {
        throw new Error('No user id');
      }
      return apis.employee
        .getEmployeeById({ tenantId, employeeId: employeeId, includeRegistrationToken: true })
        .then(res => res.data)
        .catch(e => {
          if (!(error instanceof AxiosError && error.response?.status === 404)) {
            withMessage(e);
          }
        });
    },
    {
      retry: (failureCount, error) => failureCount < 3 && !(error instanceof AxiosError && error.response?.status === 404),
    },
  );

  const navigateToEmployeeList = useNavigateBack('/employees');
  const navigateToEmployee = useNavigateBack(`/employees/${employee?.id}`);

  const handleEditProfile = useCallback(() => {
    if (editActive) {
      navigateToEmployee();
    } else {
      navigate(`/employees/${employee?.id}/profile`);
    }
  }, [editActive, employee?.id, navigate, navigateToEmployee]);

  const breadcrumbs: BreadcrumbLink[] = useMemo(() => {
    const result = [{ title: t('title'), to: '/employees' }];
    if (!isRoot) {
      result.push({ title: `${renderUserName(employee?.user, employee?.employeeNumber)}`, to: `/employees/${employee?.id}` });
    }
    return result;
  }, [employee?.employeeNumber, employee?.id, employee?.user, isRoot, t]);

  return (
    <AuthorizedLayout title={title || renderUserName(employee?.user, employee?.employeeNumber)} breadcrumbs={breadcrumbs}>
      {error instanceof AxiosError && error.response?.status === 404 ? (
        <EmployeeNotFound />
      ) : isLoading ? (
        <LinearProgress />
      ) : (
        <UserProfileSubPage
          employee={employee!}
          editActive={editActive}
          cardContent={children}
          onEdit={handleEditProfile}
          onCancel={isRoot ? navigateToEmployeeList : navigateToEmployee}
        />
      )}
    </AuthorizedLayout>
  );
};
