import { Card, CardContent, Skeleton } from '@mui/material';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { GroupedCarousel } from '../../component/carousel/GroupedCarousel';
import { DashboardBenefitButton, DashboardBenefitUpsellingButton, DashboardCustomBenefitButton } from './DashboardBenefitButton';

export const DashboardBenefitList: React.FC = () => {
  const { tenant } = useTenant();

  const { data: financialReport, isLoading: isLoadingReport } = useTenantQuery(
    ['benefits', 'financialReport', apis.benefits.getFinancialReport],
    tenantId => apis.benefits.getFinancialReport({ tenantId }).then(res => res.data),
    { staleTime: Infinity },
  );

  const { data: customBenefits, isLoading: isLoadingCustomBenefits } = useTenantQuery(
    ['benefits', 'custom', { withImage: true }, apis.customBenefits.listCustomBenefits.name],
    tenantId => apis.customBenefits.listCustomBenefits({ tenantId, withImage: true }).then(resp => resp.data),
    { staleTime: Infinity, enabled: tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Custom) },
  );

  const { data: benefitUpsellings, isLoading: isLoadingUpsellings } = useTenantQuery(
    ['benefits', 'upsellings', apis.benefits.listBenefitUpsellings],
    tenantId => apis.benefits.listBenefitUpsellings({ tenantId }).then(res => res.data),
    { staleTime: Infinity },
  );

  if (isLoadingReport || isLoadingCustomBenefits || isLoadingUpsellings) {
    return (
      <GroupedCarousel spacing={1.5}>
        {new Array(3).fill(0).map((value, index) => (
          <Card key={index} variant="outlined">
            <Skeleton height={100} variant="rectangular" />
            <CardContent>
              <Skeleton height={26} sx={{ mb: 1 }} />
              <Skeleton height={18} />
              <Skeleton height={18} />
            </CardContent>
          </Card>
        ))}
      </GroupedCarousel>
    );
  }

  return (
    <GroupedCarousel autoPlay spacing={1.5}>
      {financialReport?.benefits.map(reportBenefit => (
        <DashboardBenefitButton
          key={reportBenefit.benefit}
          benefit={reportBenefit.benefit}
          grant={reportBenefit.grant}
          savings={reportBenefit.savings}
        />
      ))}
      {customBenefits?.results.map(customBenefit => <DashboardCustomBenefitButton key={customBenefit.id} customBenefit={customBenefit} />)}
      {benefitUpsellings?.results.map(upsellingItem => (
        <DashboardBenefitUpsellingButton
          key={`${upsellingItem.benefit}${upsellingItem.customBenefitTemplateKey}`}
          upselling={upsellingItem}
        />
      ))}
    </GroupedCarousel>
  );
};
