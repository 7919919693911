import { BenefitDTOBenefitEnum, LunchReceiptTenantDTO, MobilityReceiptTenantDTO, RecreationReceiptTenantDTO } from 'probonio-shared-ui/api';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { useNewDialogState } from '../../component/dialog';
import { ReportDiagram } from '../../module/benefits';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { ReceiptDetailsDrawer } from '../../module/benefits/ReceiptList/ReceiptDetailsDrawer';
import { ReceiptList } from '../../module/benefits/ReceiptList/ReceiptList';
import { useLunchReport } from '../../module/benefits/lunch/useLunchReport';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';

const LunchReceiptsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { reportMonth, report, isLoading } = useLunchReport();
  const [selectedReceipt, setSelectedReceipt] = useState<LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO>();
  const { dialogState: drawerDialogState, handleOpen: handleOpenDrawer } = useNewDialogState();

  const handleSelectReceipt = useCallback(
    (receipt: LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO) => {
      setSelectedReceipt(receipt);
      handleOpenDrawer();
    },
    [handleOpenDrawer],
  );

  return (
    <AuthorizedLayout title={t('subPage.receipts')} breadcrumbs={[{ title: t('LUNCH.title'), to: '/benefits/lunch' }]}>
      <BenefitSubPage
        icon={BENEFIT_ICONS.LUNCH}
        title={t('LUNCH.title')}
        subTitle={t('subPage.receipts')}
        menu={<ReportDiagram report={report} isLoading={isLoading} reportMonth={reportMonth} />}
      >
        <BenefitPaper>
          <ReceiptList
            benefit={BenefitDTOBenefitEnum.Lunch}
            selectedReceipt={drawerDialogState.isOpen ? selectedReceipt : undefined}
            onSelectReceipt={handleSelectReceipt}
            onReceiptUpdated={setSelectedReceipt}
          />
          {selectedReceipt && (
            <ReceiptDetailsDrawer dialogState={drawerDialogState} benefit={BenefitDTOBenefitEnum.Lunch} receipt={selectedReceipt} />
          )}
        </BenefitPaper>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};

export default LunchReceiptsPage;
