import { Alert, Grid } from '@mui/material';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import {
  BenefitActivationDTO,
  BenefitTemplateItemDTO,
  CreateBenefitActivationDTO,
  UpdateBenefitActivationDTO,
} from 'probonio-shared-ui/api';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BasicDialog } from '../../../component/dialog';
import { DialogLoadingButton } from '../../../component/dialog/DialogLoadingButton';
import { DatePickerControl } from '../../../component/form';
import { ActivationDialogState } from './useActivationDialogState';

export interface BenefitActivationValues<T> {
  editMode?: boolean;
  startDate: Date;
  endDate?: Date;
  benefitOptions: T;
}

export interface BaseActivationDialogProps<T> {
  activationDialogState: ActivationDialogState;
  isLoading?: boolean;
  activeEndDate?: DateTime;
  onCreateActivation: (
    baseValues: Pick<CreateBenefitActivationDTO, 'startDate' | 'endDate'>,
    benefitOptions: T,
    saveActivation: (create: CreateBenefitActivationDTO) => void | Promise<void>,
  ) => void | Promise<void>;
  onUpdateActivation?: (
    baseValues: Pick<UpdateBenefitActivationDTO, 'startDate' | 'endDate'>,
    benefitOptions: T,
    saveActivation: (update: UpdateBenefitActivationDTO) => void | Promise<void>,
  ) => void | Promise<void>;
  onSaveCreateBenefitActivationDTO: (create: CreateBenefitActivationDTO) => void | Promise<void>;
  onSaveUpdateBenefitActivationDTO?: (benefitActivationId: string, update: UpdateBenefitActivationDTO) => void | Promise<void>;
  onResetBenefitOptions: (selectedActivation?: BenefitActivationDTO | BenefitTemplateItemDTO) => T;
  header?: React.ReactNode;
  fields?: React.ReactNode;
  inlineField?: React.ReactNode;
  createLabel?: string;
  earliestStartDate?: DateTime;
}

export const BaseActivationDialog = <T extends object>({
  activationDialogState,
  isLoading,
  activeEndDate,
  onCreateActivation,
  onUpdateActivation,
  onSaveCreateBenefitActivationDTO,
  onSaveUpdateBenefitActivationDTO,
  onResetBenefitOptions,
  header,
  fields,
  inlineField,
  createLabel,
  earliestStartDate,
}: BaseActivationDialogProps<T>): JSX.Element => {
  const { t } = useTranslation('benefitsModule');
  const { activation: selectedActivation, dialogState } = activationDialogState;
  const formMethods = useForm<BenefitActivationValues<T>>();
  const { control, watch, handleSubmit, setValue, reset, formState } = formMethods;
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const startOfMonth = DateTime.now().startOf('month').toJSDate();

  useEffect(() => {
    if (!endDate) return;
    if (DateTime.fromJSDate(startDate).endOf('month') > DateTime.fromJSDate(endDate).endOf('month')) {
      setValue('endDate', startDate);
    }
  }, [startDate, endDate, setValue]);

  const minStartDate = useMemo(() => {
    return (activeEndDate ? activeEndDate.plus({ months: 1 }) : earliestStartDate || DateTime.now().startOf('month')).toJSDate();
  }, [activeEndDate, earliestStartDate]);

  useEffect(() => {
    if (!dialogState.isOpen) return;
    reset({
      editMode: !!selectedActivation,
      startDate: selectedActivation ? new Date(selectedActivation.startDate) : minStartDate,
      endDate: selectedActivation?.endDate ? new Date(selectedActivation.endDate) : undefined,
      benefitOptions: onResetBenefitOptions(selectedActivation),
    });
  }, [dialogState.isOpen, selectedActivation, reset, onResetBenefitOptions, minStartDate]);

  const handleSaveActivation = useCallback(
    async ({ startDate, endDate, benefitOptions }: BenefitActivationValues<T>) => {
      try {
        if (!selectedActivation) {
          await onCreateActivation(
            {
              startDate: DateTime.fromJSDate(startDate).toFormat('yyyy-MM'),
              endDate: endDate ? DateTime.fromJSDate(endDate).toFormat('yyyy-MM') : undefined,
            },
            benefitOptions,
            onSaveCreateBenefitActivationDTO,
          );
        } else if (onUpdateActivation && onSaveUpdateBenefitActivationDTO) {
          await onUpdateActivation(
            {
              startDate:
                startDate &&
                DateTime.fromJSDate(startDate).toFormat('yyyy-MM') !== DateTime.fromISO(selectedActivation.startDate).toFormat('yyyy-MM')
                  ? DateTime.fromJSDate(startDate).toFormat('yyyy-MM')
                  : undefined,
              endDate: endDate ? DateTime.fromJSDate(endDate).toFormat('yyyy-MM') : null,
            },
            benefitOptions,
            update => onSaveUpdateBenefitActivationDTO(selectedActivation.id, update),
          );
        }
      } catch (err) {
        if (!(err instanceof AxiosError)) {
          // rethrow non-axios errors as they are probably not handled
          throw err;
        }
        // do not close dialog on error; error handling is done in mutations already
        return;
      }
      dialogState.handleClose();
    },
    [
      dialogState,
      onCreateActivation,
      onSaveCreateBenefitActivationDTO,
      onSaveUpdateBenefitActivationDTO,
      onUpdateActivation,
      selectedActivation,
    ],
  );

  return (
    <BasicDialog
      dialogState={dialogState}
      title={selectedActivation ? t('dialogEditTitle') : t('dialogCreateTitle')}
      content={
        <form data-test-id="date-picker-control" onSubmit={handleSubmit(handleSaveActivation)}>
          <FormProvider {...formMethods}>
            {header}
            {selectedActivation && DateTime.fromISO(selectedActivation.startDate).diffNow('months').months <= -1 ? (
              <Alert severity="warning" sx={{ mb: 1 }}>
                {t([`benefitSplitAlert.${selectedActivation.benefit}`, 'benefitSplitAlert.default'])}
              </Alert>
            ) : null}
            <Grid container columns={inlineField ? 16 : 12} columnSpacing={inlineField ? 1 : 2} marginBottom={1}>
              <Grid item xs={6}>
                <DatePickerControl
                  dataTestId="date-picker-start"
                  minDate={minStartDate}
                  maxDate={DateTime.now().plus({ years: 10 }).toJSDate()}
                  control={control}
                  name="startDate"
                  label={t('fields.dateFrom')}
                  helperText={
                    selectedActivation
                      ? ''
                      : earliestStartDate && earliestStartDate >= DateTime.now().plus({ months: 1 }).startOf('month')
                        ? t('helperText.dateNextMonth')
                        : t('helperText.dateFrom')
                  }
                  fullWidth
                  margin="dense"
                  rules={{
                    required: !selectedActivation,
                    validate: {
                      minDate: (value: unknown) =>
                        (value instanceof Date && DateTime.fromJSDate(value) >= DateTime.now().startOf('month')) || !!selectedActivation,
                    },
                  }}
                  disabled={selectedActivation && DateTime.fromISO(selectedActivation.startDate) < DateTime.now()}
                  monthYearPicker
                />
              </Grid>
              <Grid item xs={6}>
                <DatePickerControl
                  dataTestId="date-picker-end"
                  dateFormat="MMMM y"
                  control={control}
                  name="endDate"
                  label={t('fields.dateTill')}
                  helperText={t('helperText.dateTill')}
                  fullWidth
                  margin="dense"
                  minDate={startDate > startOfMonth ? startDate : startOfMonth}
                  maxDate={DateTime.now().plus({ years: 10 }).toJSDate()}
                  monthYearPicker
                />
              </Grid>
              {inlineField && (
                <Grid item xs={4}>
                  {inlineField}
                </Grid>
              )}
            </Grid>
            {fields}
          </FormProvider>
        </form>
      }
      actions={
        <DialogLoadingButton
          loading={isLoading || formState.isSubmitting}
          onClick={handleSubmit(handleSaveActivation)}
          disabled={formMethods.formState.errors?.root?.message === 'error'}
          data-test-id="activate-dialog-button-submit"
        >
          {selectedActivation ? t('common:buttons.save') : createLabel || t('common:buttons.activate')}
        </DialogLoadingButton>
      }
    />
  );
};
