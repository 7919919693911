import { Box, FormGroup, FormLabel, Grid, InputAdornment, MenuItem } from '@mui/material';
import {
  BenefitActivationDTO,
  BenefitDTOBenefitEnum,
  BenefitTemplateItemDTO,
  BikeBenefitOptionsDTOEmployerExtrasEnum,
  CreateBenefitActivationDTO,
  UpdateBenefitActivationDTO,
  UpdateTenantDTO,
} from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isURL from 'validator/lib/isURL';
import { SelectControl, TextFieldControl } from '../../../component/form';
import { CheckboxControl } from '../../../component/form/CheckboxControl';
import { ActivationDialogExtensions } from '../ActivationDialog';
import bikeleasingIcon from './icons/bikeleasing.png';
import businessBikeIcon from './icons/businessBikeScaled.png';
import euroradIcon from './icons/eurorad.svg';
import jobRadIcon from './icons/jobrad.png';
import leaseABikeIcon from './icons/lease-a-bike-logo.ico';

interface BikeBenefitOptions {
  employerGrant: number;
  employerExtras: Partial<Record<BikeBenefitOptionsDTOEmployerExtrasEnum, boolean>>;
  platformName: string;
  platformUrl: string;
}

const PLATFORM_NAMES = ['JobRad', 'EURORAD', 'Bikeleasing.de', 'Businessbikeleasing', 'Lease-a-bike'];
const PLATFORM_ICONS = [jobRadIcon, euroradIcon, bikeleasingIcon, businessBikeIcon, leaseABikeIcon];
const PLATFORM_OTHER = '__OTHER__';

const Fields: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { control } = useFormContext();

  return (
    <Grid container columnSpacing={2} rowSpacing={1} marginTop={1}>
      <Grid item xs={6}>
        <SelectControl
          control={control}
          name="benefitOptions.platformName"
          label={t('BIKE.fields.platformName')}
          fullWidth
          margin="dense"
          rules={{ required: true }}
          dataTestId="bike-platform-name"
        >
          {PLATFORM_NAMES.map((platformName, index) => (
            <MenuItem key={platformName} value={platformName} data-test-id={`option-${platformName}`}>
              <Box display="flex" alignItems="center">
                <Box component="img" alt="logo" src={PLATFORM_ICONS[index]} width={23} height={23} mr={1} /> {platformName}
              </Box>
            </MenuItem>
          ))}
          <MenuItem value={PLATFORM_OTHER}>{t('BIKE.otherProvider')}</MenuItem>
        </SelectControl>
      </Grid>
      <Grid item xs={6}>
        <TextFieldControl
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          inputProps={{ step: '1' }}
          rules={{
            required: true,
            min: 0,
            max: 1000,
          }}
          type="number"
          control={control}
          variant="outlined"
          name="benefitOptions.employerGrant"
          label={t('BIKE.fields.employerGrant')}
          margin="dense"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldControl
          rules={{
            required: true,
            validate: { url: (value: string) => (isURL(value.trim(), { require_protocol: true }) ? undefined : t('BIKE.invalidUrl')) },
          }}
          control={control}
          variant="outlined"
          name="benefitOptions.platformUrl"
          label={t('BIKE.fields.platformUrl')}
          margin="dense"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <FormLabel>{t('BIKE.fields.employerExtras')}</FormLabel>
          {Object.values(BikeBenefitOptionsDTOEmployerExtrasEnum).map(extra => (
            <CheckboxControl
              key={extra}
              control={control}
              name={`benefitOptions.employerExtras.${extra}`}
              label={t(`BIKE.employerExtra.${extra}`)}
            />
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export function useBikeDialogExtensions(): ActivationDialogExtensions<BikeBenefitOptions> {
  const { tenant, invalidateTenant } = useTenant();
  const handleResetBenefitOptions = useCallback(
    (selectedActivation?: BenefitActivationDTO | BenefitTemplateItemDTO): BikeBenefitOptions => {
      return {
        employerGrant: selectedActivation?.bikeOptions ? selectedActivation.bikeOptions.employerGrant / 100 : 0,
        employerExtras: selectedActivation?.bikeOptions?.employerExtras
          ? Object.fromEntries(
              Object.values(BikeBenefitOptionsDTOEmployerExtrasEnum).map(extra => [
                extra,
                selectedActivation?.bikeOptions?.employerExtras.includes(extra),
              ]),
            )
          : { [BikeBenefitOptionsDTOEmployerExtrasEnum.Insurance]: true },
        platformName: selectedActivation?.bikeOptions
          ? selectedActivation.bikeOptions.platformName || PLATFORM_OTHER
          : tenant?.bikePlatformName || '',
        platformUrl: selectedActivation?.bikeOptions?.platformUrl || tenant?.bikePlatformUrl || '',
      };
    },
    [tenant],
  );

  const mapBikeOptions = useCallback(
    (benefitOptions: BikeBenefitOptions) => ({
      employerGrant: Math.round(benefitOptions.employerGrant * 100),
      employerExtras: Object.values(BikeBenefitOptionsDTOEmployerExtrasEnum).filter(extra => benefitOptions.employerExtras[extra]),
      platformName: benefitOptions.platformName === PLATFORM_OTHER ? null : benefitOptions.platformName,
      platformUrl: benefitOptions.platformUrl.trim(),
    }),
    [],
  );
  const handleCreateActivation = useCallback<ActivationDialogExtensions<BikeBenefitOptions>['onCreateActivation']>(
    async (baseValues, benefitOptions, saveActivation) => {
      const create: CreateBenefitActivationDTO = {
        ...baseValues,
        benefit: BenefitDTOBenefitEnum.Bike,
        bikeOptions: mapBikeOptions(benefitOptions),
      };
      await saveActivation(create);
      const update: Partial<UpdateTenantDTO> = {};
      if (tenant?.bikePlatformName !== benefitOptions.platformName) {
        update.bikePlatformName = benefitOptions.platformName;
      }
      if (tenant?.bikePlatformUrl !== benefitOptions.platformUrl) {
        update.bikePlatformUrl = benefitOptions.platformUrl;
      }
      if (Object.keys(update).length > 0) {
        await apis.tenants.updateTenant({ tenantId: tenant!.id, updateTenantDTO: update });
        invalidateTenant();
      }
    },
    [invalidateTenant, mapBikeOptions, tenant],
  );
  const handleUpdateActivation = useCallback<NonNullable<ActivationDialogExtensions<BikeBenefitOptions>['onUpdateActivation']>>(
    async (baseValues, benefitOptions, saveActivation) => {
      const update: UpdateBenefitActivationDTO = {
        ...baseValues,
        bikeOptions: mapBikeOptions(benefitOptions),
      };
      await saveActivation(update);
    },
    [mapBikeOptions],
  );
  return useMemo(
    () => ({
      Fields,
      onResetBenefitOptions: handleResetBenefitOptions,
      onCreateActivation: handleCreateActivation,
      onUpdateActivation: handleUpdateActivation,
    }),
    [handleCreateActivation, handleResetBenefitOptions, handleUpdateActivation],
  );
}
