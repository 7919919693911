import EmailIcon from '@mui/icons-material/Email';
import EventIcon from '@mui/icons-material/Event';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Avatar, Box, Button, Grid, styled, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../component/dialog';
import { HUBSPOT_CALENDAR_LINK, PROBONIO_MAIL, PROBONIO_TEL } from '../../constants';
import support1 from './support1.jpeg';
import support2 from './support2.jpeg';
import support3 from './support3.jpeg';

const SupportAvatar = styled(Avatar)(({ theme }) => ({
  width: 94,
  height: 94,
  padding: 4,
  backgroundColor: '#fff',
  marginLeft: -20,
  '>img': {
    borderRadius: '100%',
  },
}));

export const SupportDialog: React.FC<{ dialogState: DialogState }> = ({ dialogState }) => {
  const { t } = useTranslation('homePage');

  return (
    <BasicDialog
      dialogState={dialogState}
      title={<Trans i18nKey="homePage:support.dialogTitle" />}
      content={
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginLeft: 1 }}>
            <SupportAvatar src={support1} />
            <SupportAvatar src={support2} />
            <SupportAvatar src={support3} />
          </Box>
          <Box paddingTop={2}>
            <Typography variant="body1">{t('support.description')}</Typography>
          </Box>
          <Grid paddingTop={2} container>
            <Grid item xs={3} />
            <Grid item xs={6}>
              <Button
                variant="text"
                href="https://handbuch.probonio.de"
                sx={{ justifyContent: 'flex-start', marginBottom: 1 }}
                fullWidth
                startIcon={<MenuBookIcon />}
              >
                {t('support.manual')}
              </Button>
              <Button
                variant="text"
                href="https://wa.me/4915733775777"
                sx={{ justifyContent: 'flex-start', marginBottom: 1 }}
                fullWidth
                startIcon={<WhatsAppIcon />}
              >
                {t('support.whatsApp')}
              </Button>
              <Button
                variant="text"
                href={HUBSPOT_CALENDAR_LINK}
                target="_blank"
                startIcon={<EventIcon />}
                fullWidth
                sx={{ justifyContent: 'flex-start', marginBottom: 1 }}
              >
                {t('support.calendar')}
              </Button>
              <Button
                variant="text"
                href={`mailto:${PROBONIO_MAIL}`}
                startIcon={<EmailIcon />}
                fullWidth
                sx={{ justifyContent: 'flex-start', marginBottom: 1 }}
              >
                {PROBONIO_MAIL}
              </Button>
              <Button
                variant="text"
                href={`tel:${PROBONIO_TEL.replace(/\s/g, '')}`}
                startIcon={<PhoneIcon />}
                fullWidth
                sx={{ justifyContent: 'flex-start', marginBottom: 0.5 }}
              >
                {PROBONIO_TEL}
              </Button>
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </>
      }
    />
  );
};
