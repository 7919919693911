import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, Grid, IconButton, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import { TenantSepaConfigDTO } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis, uploadFileToS3 } from 'probonio-shared-ui/module/api';
import { useTenant, useTenantID } from 'probonio-shared-ui/module/me';
import { calculateBlobSHA256, hasStoredHash, removeStoredHash, storeHash } from 'probonio-shared-ui/utils/hashStorage';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../component/dialog';
import { ConfirmationModal } from '../../component/dialog/ConfirmationModal';
import { UploadControl } from '../../component/form/UploadControl';
import { TENANT_MAX_PDF_SIZE, TENANT_MIN_PDF_SIZE } from '../../constants';
import { SepaStepHeader } from './SepaStepHeader';

interface Props {
  sepa?: TenantSepaConfigDTO;
  type: 'uploadMandate' | 'uploadConfirmation';
  onBack: () => void;
  onNext: () => void;
}

interface FormValues {
  file?: File;
}

function validateFileSize(value: string | File | undefined, type: Props['type'], t: TFunction): string | true {
  if (value && typeof value !== 'string' && (value.size > TENANT_MAX_PDF_SIZE || value.size < TENANT_MIN_PDF_SIZE)) {
    return t('paymentMethods.maxFileSize') as string;
  }
  return true;
}

async function validateHashConflict(value: string | File | undefined, type: Props['type'], t: TFunction): Promise<string | true> {
  if (value && typeof value !== 'string') {
    const hash = await calculateBlobSHA256(value);
    const keys = ['sepaTemplate'];
    if (type === 'uploadConfirmation') {
      keys.push('uploadMandate');
    }
    if (hasStoredHash(keys, hash)) {
      return t(`paymentMethods.fileConflict.${type}`) as string;
    }
  }
  return true;
}

export const SepaMandateUpload: React.FC<Props> = ({ sepa, type, onBack, onNext }) => {
  const { t } = useTranslation('tenantModule');
  const getTenantId = useTenantID();
  const { invalidateTenant } = useTenant();
  const { control, handleSubmit } = useForm<FormValues>();
  const confirmClearDialog = useNewDialogState();

  const saveMutation = useMutation({
    mutationFn: async (file: File) => {
      const uploadLink = await apis.sepa.createSepaUploadLink({ tenantId: getTenantId() }).then(res => res.data);
      await uploadFileToS3(uploadLink, file, file.type);
      const fileKey = uploadLink.key;
      const tenantField = type === 'uploadMandate' ? 'sepaMandateFileKey' : 'sepaConfirmationFileKey';
      await apis.sepa.updateTenantSepaMandate({
        tenantId: getTenantId(),
        updateTenantSepaConfigDTO: {
          [tenantField]: fileKey,
        },
      });
      const hash = await calculateBlobSHA256(file);
      storeHash(type, hash);
    },

    onSuccess: () => {
      invalidateTenant();
      onNext();
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async () => {
      const tenantField = type === 'uploadMandate' ? 'sepaMandateFileKey' : 'sepaConfirmationFileKey';
      await apis.sepa.updateTenantSepaMandate({
        tenantId: getTenantId(),
        updateTenantSepaConfigDTO: {
          [tenantField]: null,
        },
      });
      removeStoredHash(type);
      if (type === 'uploadMandate') {
        removeStoredHash('uploadConfirmation');
      }
    },
    onSuccess: () => {
      confirmClearDialog.dialogState.handleClose();
      invalidateTenant();
    },
  });

  const handleSave = useCallback(
    (values: FormValues) => {
      saveMutation.mutate(values.file!);
    },
    [saveMutation],
  );

  const handleClear = useCallback(() => {
    deleteMutation.mutate();
  }, [deleteMutation]);

  const fileUrl = type === 'uploadMandate' ? sepa?.sepaMandateURL : sepa?.sepaConfirmationURL;
  const isEditable = sepa?.sepa && !sepa?.sepaMandate;

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <SepaStepHeader
        title={t(`paymentMethods.sepaStepper.step.${type}.header`)}
        text={t(`paymentMethods.sepaStepper.step.${type}.info`)}
        complete={!!fileUrl}
      />
      <Grid container marginBottom={2.5}>
        <Grid item xs={6} lg={4}>
          {fileUrl ? (
            <Box>
              <Button variant="text" href={fileUrl} startIcon={<DownloadIcon />} target="_blank">
                {t(`paymentMethods.sepaStepper.step.${type}.downloadLink`)}
              </Button>
              {isEditable && (
                <IconButton title="Zurücksetzen" onClick={confirmClearDialog.handleOpen}>
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          ) : (
            <UploadControl
              control={control}
              name="file"
              fullWidth
              accept="application/pdf"
              rules={{
                required: true,
                validate: { fileSize: value => validateFileSize(value, type, t), hash: value => validateHashConflict(value, type, t) },
              }}
              data-test-id={`sepa-upload-${type}`}
            />
          )}
        </Grid>
      </Grid>
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <Button variant="text" size="small" onClick={onBack} disabled={saveMutation.isPending}>
          {t('common:buttons.back')}
        </Button>
        {fileUrl ? (
          <Button size="small" onClick={onNext}>
            {t('common:buttons.next')}
          </Button>
        ) : (
          <LoadingButton size="small" type="submit" loading={saveMutation.isPending} data-test-id="sepa-button-next">
            {t('common:buttons.next')}
          </LoadingButton>
        )}
      </Stack>
      <ConfirmationModal
        dialogState={confirmClearDialog.dialogState}
        onConfirm={handleClear}
        title={t(`paymentMethods.sepaStepper.step.${type}.clearTitle`)}
        loading={deleteMutation.isPending}
        closeOnConfirm={false}
      >
        {t(`paymentMethods.sepaStepper.step.${type}.clearText`)}
      </ConfirmationModal>
    </form>
  );
};
