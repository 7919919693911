import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Tooltip } from '@mui/material';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DashboardPanel } from '../../component/dashboard/DashboardPanel';
import { BENEFIT_ICONS } from '../benefits/BenefitIcon';
import { DashboardBenefitList } from './DashboardBenefitList';
import { TextWithCaret, TextWithCaretPlaceholder } from './TextWithCaret';

export const BenefitsDashboardPanel: React.FC<{ rows?: number }> = ({ rows }) => {
  const { t } = useTranslation('dashboardModule');

  const { data: financialReport, isLoading } = useTenantQuery(
    ['benefits', 'financialReport', apis.benefits.getFinancialReport],
    tenantId => apis.benefits.getFinancialReport({ tenantId }).then(res => res.data),
    { staleTime: Infinity },
  );

  return (
    <DashboardPanel
      rows={rows}
      title={t('benefitsPanel.title')}
      subTitle={
        <>
          <Trans i18nKey="dashboardModule:benefitsPanel.totalSavings" values={{ amount: financialReport?.totalSavings }}>
            {isLoading ? <TextWithCaretPlaceholder /> : <TextWithCaret color="success.main" />}
          </Trans>
          <Tooltip title={<Trans i18nKey="dashboardModule:benefitsPanel.disclaimer" />}>
            <InfoOutlinedIcon fontSize="small" sx={{ marginLeft: 1, marginY: -0.5 }} />
          </Tooltip>
        </>
      }
      actions={
        <Button
          component={Link}
          to="/benefits/custom"
          variant="contained"
          size="small"
          color="secondary"
          startIcon={<BENEFIT_ICONS.CUSTOM />}
        >
          {t('common:benefit.CUSTOM')}
        </Button>
      }
    >
      <DashboardBenefitList />
    </DashboardPanel>
  );
};
