import { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MobileInfoPage } from 'probonio-shared-ui/component/infoPage';
import { PREVIEW_WIDTH } from '../PreviewStepper';

interface Props {
  html: string | undefined;
  image: string;
  benefitTitle: string;
}

export const HelpPreview: React.FC<Props> = ({ html, image, benefitTitle }) => {
  const theme = useTheme();
  const [imageUrl, setImageUrl] = useState<string | undefined>('');

  useEffect(() => {
    if (typeof image === 'string' || !image) {
      setImageUrl(image);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setImageUrl(objectUrl);

    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [image, setImageUrl]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="start"
      p={1}
      width={PREVIEW_WIDTH}
      height="100%"
      sx={{ backgroundColor: theme.palette.background.default }}
    >
      <Box display="flex" position="relative" justifyContent="center" mx={1} my={2} px={2.5} textAlign="center">
        <ArrowBackIcon style={{ position: 'absolute', left: 0, marginRight: '30px' }} />
        <Typography sx={{ wordBreak: 'break-word' }} variant="h2">
          {benefitTitle}
        </Typography>
      </Box>
      <Box overflow="auto">
        <MobileInfoPage image={imageUrl}>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: html || '' }} />
        </MobileInfoPage>
      </Box>
    </Box>
  );
};
