import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Badge, Box, Button, ClickAwayListener, Divider, Drawer, IconButton, Typography, styled, useTheme } from '@mui/material';
import React, { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TenantTutorialStepper } from './TenantTutorialStepper';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { useLocation } from 'react-router-dom';
import { CircularProgressBar } from 'probonio-shared-ui/component/progress';
import { MAIN_MENU_WIDTH } from '../../navigation/MainMenuDrawer';
import SimpleBar from 'simplebar-react';
import { useTutorialContext } from './tutorialContext';

const DRAWER_WIDTH = 300;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // necessary for content to be below app bar
  justifyContent: 'left',
}));

const OpenButton = styled(Button, { shouldForwardProp: prop => prop !== 'hide' })<{
  hide?: boolean;
}>(({ theme, hide }) => ({
  position: 'fixed',
  zIndex: 1,
  left: MAIN_MENU_WIDTH,
  top: 200,
  transform: `translateX(${hide ? '-30px' : 0}) rotate(90deg)`,
  [theme.breakpoints.down('xl')]: {
    transform: `rotate(90deg)`,
    left: 0,
  },
  transformOrigin: 'left bottom',
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  height: 30,
}));

const MobileDrawer = React.forwardRef<HTMLDivElement, { children?: React.ReactNode; open?: boolean; onClose: () => void }>(
  ({ children, open, onClose }, ref) => (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          borderRight: 'none',
          overflowY: 'unset',
        },
      }}
      PaperProps={{ ref, elevation: 16 }}
      SlideProps={{ mountOnEnter: true, unmountOnExit: true }}
    >
      {children}
    </Drawer>
  ),
);

export const TutorialDrawer: React.FC<React.PropsWithChildren> = memo(({ children }) => {
  const { t } = useTranslation('tutorialModule');
  const theme = useTheme();
  const { pathname } = useLocation();

  const { steps: tutorialState, drawerState } = useTutorialContext();

  // on small screens, close the tutorial drawer when clicking a navigate button
  useEffect(() => {
    if (drawerState.open) {
      drawerState.handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const DrawerComponent = MobileDrawer;

  const handleClickAway = useCallback(
    (ev: MouseEvent | TouchEvent) => {
      if (ev.target instanceof Element && (ev.target.closest('.tutorial-opener') || ev.target.closest('.MuiDialog-root'))) {
        return;
      }
      drawerState.handleClose();
    },
    [drawerState],
  );

  return (
    <Box display="flex">
      <Box flexGrow={1} position="relative" maxWidth="100%">
        <OpenButton
          color="primary"
          hide={drawerState.open || tutorialState.openCount === 0}
          size="small"
          onClick={drawerState.handleOpen}
          data-test-id="toggle-tutorial-button"
          startIcon={<LightbulbIcon />}
          className="tutorial-opener"
        >
          <Badge badgeContent={tutorialState.openCount} color="error" invisible={drawerState.open}>
            {t('buttonOpenDrawer')}&nbsp;
          </Badge>
        </OpenButton>
        {children}
      </Box>
      <ClickAwayListener onClickAway={handleClickAway}>
        <DrawerComponent open={drawerState.open} onClose={drawerState.handleClose}>
          <DrawerHeader sx={{ justifyContent: 'space-between' }}>
            <Box p={1} display="flex" alignItems="center">
              <CircularProgressBar variant="determinate" value={100 * (1 - tutorialState.openCount / tutorialState.totalCount)} size={35}>
                {tutorialState.openCount}
              </CircularProgressBar>
              <Typography variant="h3" ml={1.25}>
                {t('buttonOpenDrawer')}
              </Typography>
            </Box>
            <IconButton onClick={drawerState.handleClose} data-test-id="close-tutorial-button">
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <SimpleBar style={{ flexGrow: 1, minHeight: 0 }}>
            <TenantTutorialStepper tutorialSteps={tutorialState.tutorialSteps} completedCount={tutorialState.completedCount} />
          </SimpleBar>
        </DrawerComponent>
      </ClickAwayListener>
    </Box>
  );
});
