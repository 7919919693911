/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { TaxConfigDTO } from './tax-config-dto';

/**
 * 
 * @export
 * @interface TenantDTO
 */
export interface TenantDTO {
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'tenantStatus': TenantDTOTenantStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantDTO
     */
    'activeBenefits'?: Array<TenantDTOActiveBenefitsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantDTO
     */
    'flexBenefits'?: Array<TenantDTOFlexBenefitsEnum>;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'lunchTaxationType'?: TenantDTOLunchTaxationTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantDTO
     */
    'reportBenefitList'?: Array<TenantDTOReportBenefitListEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantDTO
     */
    'reportDetailBenefitList'?: Array<TenantDTOReportDetailBenefitListEnum>;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'couponOrderAutomationBehaviour'?: TenantDTOCouponOrderAutomationBehaviourEnum;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'legalTermsAction'?: TenantDTOLegalTermsActionEnum;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'hrSystemType'?: TenantDTOHrSystemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'billingType'?: TenantDTOBillingTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantDTO
     */
    'additionalFeatures'?: Array<TenantDTOAdditionalFeaturesEnum>;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'mailDomain': string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'mail': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantDTO
     */
    'invoiceEmailList'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'imageURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'imageURLDark'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'street'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'country'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantDTO
     */
    'completedTutorialSteps'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantDTO
     */
    'mailIsSent'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'lodasExportTenant'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'lodasExportConsultant'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'lodasExportType'?: string;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'lodasSalaryTypeTaxFree'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'lodasSalaryTypeTaxable'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'lodasSalaryTypeMobilityTaxFree'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'lodasSalaryTypeMobilityTaxable'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'lodasSalaryTypeSalaryReduction'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'lodasSalaryTypeCoupons'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'lodasSalaryTypeGifts'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'lodasSalaryTypeIncentives'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'lodasSalaryTypeInternet'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'lodasSalaryTypeRecreation'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'lunchRestrictionHint'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'lunchRestrictionReasonText'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantDTO
     */
    'lunchRestrictionPostalCode'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'lunchPeriodCloseDelay'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'couponBudget'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantDTO
     */
    'reportEmailList'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'registrationCode'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantDTO
     */
    'couponPackages'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenantDTO
     */
    'excludedCouponPackages'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'couponOrderDeadline'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'couponFallbackDefinitionId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantDTO
     */
    'enableAutomatedBudgetOrders'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'bikePlatformName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'bikePlatformUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantDTO
     */
    'bavShowTerms'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'legalTermsLastConsentDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantDTO
     */
    'sepa'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'sepaMandate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'iban'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'bic'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'hrSystemApiKey'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantDTO
     */
    'hrSystemApiSecret'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'hrSystemLastSync'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenantDTO
     */
    'hrSystemInstantInvite': boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'hrSystemSyncError'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'hrSystemMailFilter'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'hrSystemSubcompanyFilter'?: string;
    /**
     * 
     * @type {TaxConfigDTO}
     * @memberof TenantDTO
     */
    'taxConfig'?: TaxConfigDTO;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'employeeCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TenantDTO
     */
    'activeEmployeeCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TenantDTO
     */
    'showStatisticsAtTop': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantDTO
     */
    'accountingAutoSend'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'primaryColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'secondaryColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'backgroundColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'primaryColorDark'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'secondaryColorDark'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantDTO
     */
    'backgroundColorDark'?: string;
}

export const TenantDTOTenantStatusEnum = {
    Active: 'ACTIVE',
    TestPeriod: 'TEST_PERIOD',
    Inactive: 'INACTIVE',
    Deleted: 'DELETED'
} as const;

export type TenantDTOTenantStatusEnum = typeof TenantDTOTenantStatusEnum[keyof typeof TenantDTOTenantStatusEnum];
export const TenantDTOActiveBenefitsEnum = {
    Bav: 'BAV',
    Lunch: 'LUNCH',
    Coupons: 'COUPONS',
    CouponsV2: 'COUPONS_V2',
    Mobility: 'MOBILITY',
    Gifts: 'GIFTS',
    Internet: 'INTERNET',
    Bike: 'BIKE',
    News: 'NEWS',
    Flex: 'FLEX',
    Recreation: 'RECREATION',
    Bikeleasing: 'BIKELEASING',
    Custom: 'CUSTOM'
} as const;

export type TenantDTOActiveBenefitsEnum = typeof TenantDTOActiveBenefitsEnum[keyof typeof TenantDTOActiveBenefitsEnum];
export const TenantDTOFlexBenefitsEnum = {
    Bav: 'BAV',
    Lunch: 'LUNCH',
    Coupons: 'COUPONS',
    CouponsV2: 'COUPONS_V2',
    Mobility: 'MOBILITY',
    Gifts: 'GIFTS',
    Internet: 'INTERNET',
    Bike: 'BIKE',
    News: 'NEWS',
    Flex: 'FLEX',
    Recreation: 'RECREATION',
    Bikeleasing: 'BIKELEASING',
    Custom: 'CUSTOM'
} as const;

export type TenantDTOFlexBenefitsEnum = typeof TenantDTOFlexBenefitsEnum[keyof typeof TenantDTOFlexBenefitsEnum];
export const TenantDTOLunchTaxationTypeEnum = {
    SimpleTaxation: 'SIMPLE_TAXATION',
    FlatRateTaxation: 'FLAT_RATE_TAXATION'
} as const;

export type TenantDTOLunchTaxationTypeEnum = typeof TenantDTOLunchTaxationTypeEnum[keyof typeof TenantDTOLunchTaxationTypeEnum];
export const TenantDTOReportBenefitListEnum = {
    Bav: 'BAV',
    Lunch: 'LUNCH',
    Coupons: 'COUPONS',
    CouponsV2: 'COUPONS_V2',
    Mobility: 'MOBILITY',
    Gifts: 'GIFTS',
    Internet: 'INTERNET',
    Bike: 'BIKE',
    News: 'NEWS',
    Flex: 'FLEX',
    Recreation: 'RECREATION',
    Bikeleasing: 'BIKELEASING',
    Custom: 'CUSTOM'
} as const;

export type TenantDTOReportBenefitListEnum = typeof TenantDTOReportBenefitListEnum[keyof typeof TenantDTOReportBenefitListEnum];
export const TenantDTOReportDetailBenefitListEnum = {
    Bav: 'BAV',
    Lunch: 'LUNCH',
    Coupons: 'COUPONS',
    CouponsV2: 'COUPONS_V2',
    Mobility: 'MOBILITY',
    Gifts: 'GIFTS',
    Internet: 'INTERNET',
    Bike: 'BIKE',
    News: 'NEWS',
    Flex: 'FLEX',
    Recreation: 'RECREATION',
    Bikeleasing: 'BIKELEASING',
    Custom: 'CUSTOM'
} as const;

export type TenantDTOReportDetailBenefitListEnum = typeof TenantDTOReportDetailBenefitListEnum[keyof typeof TenantDTOReportDetailBenefitListEnum];
export const TenantDTOCouponOrderAutomationBehaviourEnum = {
    Default: 'DEFAULT',
    ForAllUsers: 'FOR_ALL_USERS',
    Disabled: 'DISABLED'
} as const;

export type TenantDTOCouponOrderAutomationBehaviourEnum = typeof TenantDTOCouponOrderAutomationBehaviourEnum[keyof typeof TenantDTOCouponOrderAutomationBehaviourEnum];
export const TenantDTOLegalTermsActionEnum = {
    None: 'NONE',
    Skip: 'SKIP',
    Consent: 'CONSENT'
} as const;

export type TenantDTOLegalTermsActionEnum = typeof TenantDTOLegalTermsActionEnum[keyof typeof TenantDTOLegalTermsActionEnum];
export const TenantDTOHrSystemTypeEnum = {
    Personio: 'PERSONIO'
} as const;

export type TenantDTOHrSystemTypeEnum = typeof TenantDTOHrSystemTypeEnum[keyof typeof TenantDTOHrSystemTypeEnum];
export const TenantDTOBillingTypeEnum = {
    MaxPrice: 'MAX_PRICE',
    Static: 'STATIC',
    PerBenefit: 'PER_BENEFIT',
    Free: 'FREE',
    MaxPriceBenefitActivation: 'MAX_PRICE_BENEFIT_ACTIVATION'
} as const;

export type TenantDTOBillingTypeEnum = typeof TenantDTOBillingTypeEnum[keyof typeof TenantDTOBillingTypeEnum];
export const TenantDTOAdditionalFeaturesEnum = {
    CorporateColors: 'CORPORATE_COLORS'
} as const;

export type TenantDTOAdditionalFeaturesEnum = typeof TenantDTOAdditionalFeaturesEnum[keyof typeof TenantDTOAdditionalFeaturesEnum];


