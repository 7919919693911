import { Avatar, SxProps, Theme } from '@mui/material';
import gravatar from 'gravatar';
import * as React from 'react';
import { colors } from '../../theme';

interface Props {
  onClick?: React.MouseEventHandler<HTMLDivElement>;

  firstName?: string;
  lastName?: string;
  mail?: string;
  size?: number;
  sx?: SxProps<Theme>;
}

export const LetterAvatar: React.FC<Props> = ({ onClick, firstName, lastName, mail, size = 50, sx }) => {
  const initials = (firstName && lastName ? `${firstName?.[0]}${lastName?.[0]}` : mail?.[0])?.toUpperCase();
  const bgColorHex = colors.secondary.substring(1);
  const fontColor = colors.text.primary.substring(1);
  return (
    <Avatar
      onClick={onClick}
      src={
        mail &&
        gravatar.url(mail, { default: `https://ui-avatars.com/api/${initials}/${size * 4}/${bgColorHex}/${fontColor}`, protocol: 'https' })
      }
      sx={{
        width: size,
        height: size,
        ...sx,
      }}
    />
  );
};
