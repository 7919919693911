import HistoryIcon from '@mui/icons-material/History';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  BenefitActivationDTOBenefitEnum,
  BenefitDTOBenefitEnum,
  CouponsV2GiftDTOGiftTypeEnum,
  CustomBenefitDTO,
} from 'probonio-shared-ui/api';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewDialogState } from '../../../../component/dialog';
import { ApplyTemplateDialog } from '../../../benefits/ApplyTemplateDialog';
import { BenefitDropdown } from '../../BenefitSelector';
import { useIsEmployeeTerminated } from '../../query';
import { GiftSelectionItems } from './components/GiftSelectionItems';
import ProfileActivationsList from './components/ProfileActivationsList';
import { useEmployeeActivations } from './hooks/useEmployeeActivations';
import { useProfileEmployee } from './hooks/useProfileEmployee';
import { useBenefitActivationModal } from './modals/useBenefitActivationModal';
import { useGiftActivationModal } from './modals/useGiftActivationModal ';
import { useV2GiftActivationModal } from './modals/useV2GiftActivationModal';
import { ActionCardContent } from '../../../../component/layout/ActionCardContent';
import { IconToggleButton } from 'probonio-shared-ui/component/button';
import { useNavigateWithPrevPath } from '../../../../util/useNavigateWithPrevPath';
import { useApplyBenefitTemplateMutation } from '../../../tenant/benefitTemplates/benefitTemplateQueries';

const ProfileActivations: React.FC = () => {
  const { t } = useTranslation('usersModule');
  const { activations, upcomingActivations, v2Gifts, gifts, totalActivationsCount, isLoading } = useEmployeeActivations();
  const templateDialog = useNewDialogState();
  const { employee } = useProfileEmployee();
  const navigate = useNavigateWithPrevPath();

  const { onBenefitCreate } = useBenefitActivationModal();
  const { onGiftCreate } = useGiftActivationModal();
  const { onV2GiftCreate } = useV2GiftActivationModal();
  const isOrWillBeTerminated = useIsEmployeeTerminated(employee.id);

  const handleBenefitActivationSelect = useCallback(
    (benefit: BenefitActivationDTOBenefitEnum, customBenefit: CustomBenefitDTO | undefined) => {
      onBenefitCreate(benefit, customBenefit?.id);
    },
    [onBenefitCreate],
  );

  const handleGiftActivationSelect = useCallback(
    (benefit: BenefitActivationDTOBenefitEnum, giftType?: CouponsV2GiftDTOGiftTypeEnum | undefined) => {
      if (benefit === BenefitDTOBenefitEnum.Gifts) {
        onGiftCreate();
      } else if (benefit === BenefitActivationDTOBenefitEnum.CouponsV2 && giftType) {
        onV2GiftCreate(giftType);
      }
    },
    [onGiftCreate, onV2GiftCreate],
  );

  const handleToggleHistory = useCallback(() => {
    navigate('activationHistory');
  }, [navigate]);

  const applyTemplateMutation = useApplyBenefitTemplateMutation(employee.id);

  return (
    <ActionCardContent
      title={
        <Stack direction="row" spacing={1} alignItems="center">
          <Box>{t('profileActivationList.title')}</Box>
          <IconToggleButton title={t('profileActivationHistory.buttonTooltip')} onClick={handleToggleHistory}>
            <HistoryIcon />
          </IconToggleButton>
        </Stack>
      }
      actions={
        <Stack direction="row" gap={1}>
          <Button
            size="small"
            color="secondary"
            data-test-id="apply-template"
            startIcon={<PlaylistAddIcon />}
            disabled={isOrWillBeTerminated}
            onClick={templateDialog.handleOpen}
          >
            {t('userForm.applyTemplate.button')}
          </Button>
          <BenefitDropdown
            data-test-id="add-benefit"
            size="small"
            color="primary"
            onSelect={handleBenefitActivationSelect}
            excludeBenefits={[{ benefit: BenefitDTOBenefitEnum.Gifts }, { benefit: BenefitDTOBenefitEnum.News }]}
            additionalItems={
              <GiftSelectionItems onSelect={handleGiftActivationSelect} title={t('profileActivationList.giftsSelectionGroup')} />
            }
            showTosBadge={true}
            disabled={isOrWillBeTerminated}
          >
            {t('profileActivationList.addBenefit')}
          </BenefitDropdown>
        </Stack>
      }
    >
      {totalActivationsCount <= 0 && !isLoading ? (
        <Typography variant="h3" margin="auto" textAlign="center" width="100%">
          {isOrWillBeTerminated ? t('profileActivationList.noBenefitsActiveTerminated') : t('profileActivationList.noBenefitsActive')}
        </Typography>
      ) : (
        <Box display="flex" flexDirection="column" gap={2} height="100%">
          <ProfileActivationsList activations={activations} title={t('profileActivationList.currentBenefits')} />
          <ProfileActivationsList
            activations={upcomingActivations}
            gifts={gifts}
            v2Gifts={v2Gifts}
            title={t('profileActivationList.upcomingBenefits')}
          />
        </Box>
      )}
      <ApplyTemplateDialog
        dialogState={templateDialog.dialogState}
        showActiveWarning={activations.length > 0 || upcomingActivations.length > 0}
        onApply={applyTemplateMutation.mutateAsync}
      />
    </ActionCardContent>
  );
};

export default ProfileActivations;
