import PeopleIcon from '@mui/icons-material/People';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../redux/hooks';
import { useEmployeeList } from '../../../userManagement/useEmployeeList';
import { TutorialStepDefinition } from '../TutorialStep';

export function useFirstEmployeeStep(): TutorialStepDefinition {
  const { t } = useTranslation('tutorialModule');
  const tenantId = useAppSelector(state => state.me.currentTenantId);
  const [queryEnabled, setQueryEnabled] = useState(true);
  const { data: employeeList } = useEmployeeList({ page: 0, pageSize: 0 }, { enabled: queryEnabled });

  const loading = !employeeList;
  const completed = employeeList && employeeList.totalCount > 1;

  useEffect(() => {
    if (completed) {
      setQueryEnabled(false);
    }
  }, [completed]);
  useEffect(() => {
    if (tenantId) {
      setQueryEnabled(true);
    }
  }, [tenantId]);

  return useMemo(
    () => ({
      ...t('steps.firstEmployee', { returnObjects: true }),
      path: '/employees',
      buttonIcon: PeopleIcon,
      loading,
      completed,
    }),
    [completed, loading, t],
  );
}
