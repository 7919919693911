import { Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import React, { FormEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title: React.ReactNode;
  info: React.ReactNode;
  isDirty?: boolean;
  isLoading?: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  onReset: () => void;
}

export const SettingsFormPanel: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  info,
  isDirty,
  isLoading,
  onSubmit,
  onReset,
  children,
}) => {
  const { t } = useTranslation('common');

  const handleReset = useCallback(() => {
    onReset();
  }, [onReset]);

  return (
    <form onSubmit={onSubmit}>
      <Grid container marginTop={1} flexWrap="nowrap">
        <Grid item flexGrow={1}>
          <Typography variant="h2" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {info}
          </Typography>
        </Grid>
        <Grid item>
          <Stack spacing={1} direction="row" justifyContent="center">
            <Button variant="text" size="small" type="reset" disabled={!isDirty || isLoading} onClick={handleReset}>
              {t('buttons.discard')}
            </Button>

            <Tooltip arrow placement="top" title={t('buttons.saveDisabledTooltip')} disableHoverListener={isDirty || isLoading}>
              <span>
                <LoadingButton size="small" type="submit" disabled={!isDirty} loading={isLoading} data-test-id="settings-form-panel-save">
                  {t('buttons.save')}
                </LoadingButton>
              </span>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      {children}
    </form>
  );
};
