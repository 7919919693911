import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useNewDialogState } from '../../../component/dialog';
import { useEffect } from 'react';
import { PayrollReportChooseExportTypeDialog } from './PayrollReportChooseExportTypeDialog';

export const ChooseExportTypeListener: React.FC<{ benefits: BenefitDTOBenefitEnum[] }> = ({ benefits }) => {
  const { tenant } = useTenant();

  const exportTypeDialog = useNewDialogState();

  useEffect(() => {
    if (tenant && !tenant.lodasExportType) {
      exportTypeDialog.handleOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  return <PayrollReportChooseExportTypeDialog benefits={benefits} dialogState={exportTypeDialog.dialogState} />;
};
