import { Grid, InputAdornment, TextField } from '@mui/material';
import {
  BenefitActivationDTO,
  BenefitDTOBenefitEnum,
  BenefitTemplateItemDTO,
  CreateBenefitActivationDTO,
  LunchBenefitOptionsDTOTaxationTypeEnum,
  UpdateBenefitActivationDTO,
} from 'probonio-shared-ui/api';
import { RadioControl } from 'probonio-shared-ui/component/form';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectControl, TextFieldControl } from '../../../component/form';
import { ActivationDialogExtensions } from '../ActivationDialog';

const MEALS_NUMBERS = Array(15)
  .fill(0)
  .map((e: string, i: number) => i + 1);

interface LunchBenefitOptions {
  dailyLimit: number;
  maxNumberOfReceipts: number;
  taxationType: LunchBenefitOptionsDTOTaxationTypeEnum;
  salaryConversation: boolean;
}

const Fields: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { control, watch } = useFormContext();
  const { tenant } = useTenant();

  const limit = watch('benefitOptions.dailyLimit');
  const price = watch('benefitOptions.maxNumberOfReceipts');

  const taxationMaxDailyLimit = tenant?.taxConfig && (tenant.taxConfig.lunchNonCashValue + tenant.taxConfig.lunchSupplement) / 100;

  return (
    <Grid container spacing={2} marginBottom={1}>
      <Grid item xs={4}>
        <SelectControl
          control={control}
          name="benefitOptions.maxNumberOfReceipts"
          label={t('LUNCH.fields.mealsPerMonth')}
          fullWidth
          margin="dense"
          rules={{ required: true }}
          options={MEALS_NUMBERS.map(n => ({ value: n, label: n }))}
        />
      </Grid>
      <Grid item xs={4}>
        <TextFieldControl
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          inputProps={{ step: 0.01 }}
          rules={{
            required: true,
            min: 0.01,
            max: taxationMaxDailyLimit && {
              value: taxationMaxDailyLimit,
              message: t('LUNCH.maxDailyLimitError', { value: taxationMaxDailyLimit * 100 }),
            },
          }}
          type="number"
          control={control}
          variant="outlined"
          name="benefitOptions.dailyLimit"
          label={t('LUNCH.fields.perMeal')}
          margin="dense"
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          value={(price * +limit).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          margin="dense"
          disabled
          label={t('LUNCH.fields.maxAmount')}
        />
      </Grid>
      <Grid item xs={12}>
        <RadioControl
          control={control}
          name="benefitOptions.taxationType"
          options={[
            {
              label: t('LUNCH.fields.taxationType.FLAT_RATE_TAXATION'),
              value: LunchBenefitOptionsDTOTaxationTypeEnum.FlatRateTaxation,
            },
            {
              label: t('LUNCH.fields.taxationType.SIMPLE_TAXATION'),
              value: LunchBenefitOptionsDTOTaxationTypeEnum.SimpleTaxation,
            },
          ]}
        />
      </Grid>
      {/* currently deactivated due to regulatory issues
      <Grid item xs={12}>
        <CheckboxControl disabled control={control} name="benefitOptions.salaryConversation" label={t('LUNCH.fields.salaryConversation')} />
      </Grid> */}
    </Grid>
  );
};

export function useLunchDialogExtensions(): ActivationDialogExtensions<LunchBenefitOptions> {
  const { tenant, invalidateTenant } = useTenant();

  const handleResetBenefitOptions = useCallback(
    (selectedActivation?: BenefitActivationDTO | BenefitTemplateItemDTO): LunchBenefitOptions => {
      const taxationMaxDailyLimit = tenant?.taxConfig && (tenant.taxConfig.lunchNonCashValue + tenant.taxConfig.lunchSupplement) / 100;
      return {
        dailyLimit: selectedActivation?.lunchOptions ? selectedActivation.lunchOptions.dailyLimit / 100 : taxationMaxDailyLimit || 0,
        maxNumberOfReceipts: selectedActivation?.lunchOptions ? selectedActivation.lunchOptions.maxNumberOfReceipts : 15,
        taxationType: selectedActivation?.lunchOptions
          ? selectedActivation.lunchOptions.taxationType
          : tenant?.lunchTaxationType || LunchBenefitOptionsDTOTaxationTypeEnum.FlatRateTaxation,
        salaryConversation: selectedActivation?.lunchOptions?.salaryConversation || false,
      };
    },
    [tenant],
  );

  const mapLunchOptions = useCallback(
    (benefitOptions: LunchBenefitOptions) => ({
      maxNumberOfReceipts: benefitOptions.maxNumberOfReceipts,
      dailyLimit: Math.round(benefitOptions.dailyLimit * 100),
      taxationType: benefitOptions.taxationType,
      salaryConversation: benefitOptions.salaryConversation,
    }),
    [],
  );
  const handleCreateActivation = useCallback<ActivationDialogExtensions<LunchBenefitOptions>['onCreateActivation']>(
    async (baseValues, benefitOptions, saveActivation) => {
      const create: CreateBenefitActivationDTO = {
        ...baseValues,
        benefit: BenefitDTOBenefitEnum.Lunch,
        lunchOptions: mapLunchOptions(benefitOptions),
      };
      await saveActivation(create);

      if (tenant?.lunchTaxationType !== benefitOptions.taxationType) {
        await apis.tenants.updateTenant({ tenantId: tenant!.id, updateTenantDTO: { lunchTaxationType: benefitOptions.taxationType } });
        invalidateTenant();
      }
    },
    [invalidateTenant, mapLunchOptions, tenant],
  );
  const handleUpdateActivation = useCallback<NonNullable<ActivationDialogExtensions<LunchBenefitOptions>['onUpdateActivation']>>(
    async (baseValues, benefitOptions, saveActivation) => {
      const update: UpdateBenefitActivationDTO = {
        ...baseValues,
        lunchOptions: mapLunchOptions(benefitOptions),
      };
      await saveActivation(update);

      if (tenant?.lunchTaxationType !== benefitOptions.taxationType) {
        await apis.tenants.updateTenant({ tenantId: tenant!.id, updateTenantDTO: { lunchTaxationType: benefitOptions.taxationType } });
        invalidateTenant();
      }
    },
    [invalidateTenant, mapLunchOptions, tenant],
  );

  return useMemo(
    () => ({
      Fields,
      onResetBenefitOptions: handleResetBenefitOptions,
      onCreateActivation: handleCreateActivation,
      onUpdateActivation: handleUpdateActivation,
    }),
    [handleCreateActivation, handleResetBenefitOptions, handleUpdateActivation],
  );
}
