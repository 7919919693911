import { useTranslation } from 'react-i18next';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';
import { BenefitPaper } from '../../component/benefitTabs/BenefitPaper';
import { ReceiptDelayBenefitSettingsPanel } from '../../module/benefits/lunch/ReceiptDelayBenefitSettingsPanel';

export const LunchSettingsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');

  return (
    <AuthorizedLayout title={t('subPage.settings')} breadcrumbs={[{ title: t('LUNCH.title'), to: '/benefits/lunch' }]}>
      <BenefitSubPage icon={BENEFIT_ICONS.LUNCH} title={`${t('LUNCH.title')} / ${t('MOBILITY.title')}`} subTitle={t('subPage.settings')}>
        <BenefitPaper>
          <ReceiptDelayBenefitSettingsPanel />
        </BenefitPaper>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};
