import SettingsIcon from '@mui/icons-material/Settings';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TutorialStepDefinition } from '../TutorialStep';

export function useSEPAPaymentStep(): TutorialStepDefinition {
  const { t } = useTranslation('tutorialModule');
  const { tenant } = useTenant();

  const loading = !tenant;
  const completed = tenant?.sepa && !!tenant?.sepaMandate;

  return useMemo(
    () => ({
      requiredBenefits: [BenefitDTOBenefitEnum.Coupons, BenefitDTOBenefitEnum.CouponsV2],
      ...t('steps.sepaPayment', { returnObjects: true }),
      path: '/settings',
      buttonIcon: SettingsIcon,
      loading,
      completed,
    }),
    [completed, loading, t],
  );
}
