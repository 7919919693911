import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Divider, Grid, IconButton, InputAdornment, OutlinedInputProps, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { BAVContractDTO, BAVContractDTOPaymentPeriodEnum, UpdateBAVContractDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getContractStatusColor } from './bavContractColumns';
import { ContractDocuments } from './ContractDocuments';

interface Props {
  contract: BAVContractDTO;
  changedValues: Partial<BAVContractDTO>;
  onChange: (update: (prev: Partial<BAVContractDTO>) => Partial<BAVContractDTO>) => void;
}

const LabelValueItem: React.FC<{
  label: string;
  value?: string;
  valuePostfix?: string;
  color?: string;
  editValue?: string;
  changed?: boolean;
  requestChangeTooltip?: string;
  dataTestId?: string;
  inputProps?: Partial<OutlinedInputProps>;
  onChange?: (newValue: string) => void;
}> = ({ label, value, valuePostfix, color, editValue, changed, requestChangeTooltip, dataTestId, inputProps, onChange }) => {
  const { t } = useTranslation('bavModule');
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const initialValue = editValue || value || '';

  const handleEdit = useCallback(() => {
    setInputValue(initialValue);
    setEditMode(true);
  }, [initialValue]);
  const handleCancel = useCallback(() => {
    setEditMode(false);
  }, []);
  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(ev => {
    setInputValue(ev.target.value);
  }, []);
  const handleConfirm = useCallback(() => {
    if (initialValue !== inputValue) {
      onChange!(inputValue);
    }
    setEditMode(false);
  }, [initialValue, inputValue, onChange]);

  return editMode ? (
    <form onSubmit={handleConfirm}>
      <TextField
        size="small"
        variant="outlined"
        value={inputValue}
        onChange={handleChange}
        fullWidth
        autoFocus
        InputProps={{
          ...inputProps,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" color="success" type="submit">
                <CheckCircleIcon />
              </IconButton>
              <IconButton edge="end" onClick={handleCancel}>
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  ) : (
    <Box display="flex" flexDirection="row" alignItems="center">
      {changed && (
        <Typography variant="body2" color="error.main" mr={0.5}>
          *
        </Typography>
      )}
      <Typography variant="body2" color="text.secondary" fontWeight="bold">
        {label}
      </Typography>
      {onChange && (
        <IconButton size="small" sx={{ marginLeft: 0.5, marginY: -0.5 }} title={t('requestChange')} onClick={handleEdit}>
          <EditIcon fontSize="small" />
        </IconButton>
      )}
      {requestChangeTooltip && (
        <Tooltip title={requestChangeTooltip}>
          <IconButton size="small" sx={{ marginLeft: 0.5, marginY: -0.5, backgroundColor: 'transparent !important' }}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <Typography variant="body2" fontWeight="bold" color={color} flexGrow={1} textAlign="right" data-test-id={dataTestId}>
        {value}
        {valuePostfix && (
          <Typography component="span" variant="inherit" fontWeight="normal" ml={0.5}>
            {valuePostfix}
          </Typography>
        )}
      </Typography>
    </Box>
  );
};

export const BAVContractDetails: React.FC<Props> = ({ contract, changedValues, onChange }) => {
  const { t } = useTranslation('bavModule');

  const { data: resolvedIBAN } = useQuery({
    queryKey: ['iban', contract.insuranceIBAN, apis.sepa.resolveIBAN.name],
    queryFn: () => apis.sepa.resolveIBAN({ iban: contract.insuranceIBAN }).then(res => res.data),
  });

  const handleChange = useCallback(
    (fieldName: keyof UpdateBAVContractDTO) => (value: string) => {
      let newValue: string | number = value;
      if (fieldName === 'employeePremiumGross' || fieldName === 'employerGrant' || fieldName === 'vwlConversion') {
        newValue = Number(value) * 100;
      }
      onChange(prev => ({ ...prev, [fieldName]: newValue }));
    },
    [onChange],
  );

  const isChanged = (fieldName: keyof UpdateBAVContractDTO): boolean => changedValues[fieldName] !== undefined;

  const updatedContract: BAVContractDTO = { ...contract, ...changedValues };
  const totalPremium = updatedContract.employerGrant + updatedContract.employeePremiumGross + updatedContract.vwlConversion;
  const periodPremium =
    updatedContract.paymentPeriod !== BAVContractDTOPaymentPeriodEnum.Monthly &&
    totalPremium * (updatedContract.paymentPeriod === BAVContractDTOPaymentPeriodEnum.Quarterly ? 3 : 12);

  return (
    <Grid container spacing={3} mb={3}>
      <Grid item xs={12} lg={8}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={6}>
            <Typography variant="h3" gutterBottom>
              {t('section.user')}
            </Typography>
            <Divider sx={{ marginBottom: 1 }} />
            <Stack direction="column" spacing={0.75}>
              <LabelValueItem
                label={t('contractFields.salutation')}
                value={updatedContract.salutation}
                changed={isChanged('salutation')}
                onChange={handleChange('salutation')}
              />
              <LabelValueItem
                label={t('contractFields.title')}
                value={updatedContract.title}
                changed={isChanged('title')}
                onChange={handleChange('title')}
              />
              <LabelValueItem
                label={t('contractFields.firstName')}
                value={updatedContract.firstName}
                changed={isChanged('firstName')}
                onChange={handleChange('firstName')}
              />
              <LabelValueItem
                label={t('contractFields.lastName')}
                value={updatedContract.lastName}
                changed={isChanged('lastName')}
                onChange={handleChange('lastName')}
              />
              <LabelValueItem
                label={t('contractFields.mail')}
                value={updatedContract.mail}
                changed={isChanged('mail')}
                inputProps={{ type: 'email' }}
                onChange={handleChange('mail')}
              />
              <LabelValueItem
                label={t('contractFields.dateOfBirth')}
                value={t('common:date', { date: new Date(updatedContract.dateOfBirth) })}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h3" gutterBottom>
              {t('section.contract')}
            </Typography>
            <Divider sx={{ marginBottom: 1 }} />
            <Stack direction="column" spacing={0.75}>
              <LabelValueItem label={t('contractFields.insuranceName')} value={updatedContract.insuranceName} />
              <LabelValueItem label={t('contractFields.tariffName')} value={updatedContract.tariffName} />
              <LabelValueItem label={t('contractFields.contractNumber')} value={updatedContract.contractNumber} />
              <LabelValueItem label={t('contractFields.insuranceType')} value={t(`insuranceType.${updatedContract.insuranceType}`)} />
              <LabelValueItem
                label={t('contractFields.startDate')}
                value={t('common:date', { date: new Date(updatedContract.startDate) })}
              />
              <LabelValueItem
                label={t('contractFields.mainDueDate')}
                value={t('common:date', { date: new Date(updatedContract.mainDueDate) })}
              />
              <LabelValueItem
                label={t('contractFields.status')}
                value={t(`status.${updatedContract.status}`)}
                color={getContractStatusColor(updatedContract.status)}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h3" gutterBottom>
              {t('section.address')}
            </Typography>
            <Divider sx={{ marginBottom: 1 }} />
            <Stack direction="column" spacing={0.75}>
              <LabelValueItem
                label={t('contractFields.street')}
                value={updatedContract.street}
                changed={isChanged('street')}
                onChange={handleChange('street')}
              />
              <LabelValueItem
                label={t('contractFields.postalCode')}
                value={updatedContract.postalCode}
                changed={isChanged('postalCode')}
                onChange={handleChange('postalCode')}
              />
              <LabelValueItem
                label={t('contractFields.city')}
                value={updatedContract.city}
                changed={isChanged('city')}
                onChange={handleChange('city')}
              />
              <LabelValueItem
                label={t('contractFields.country')}
                value={updatedContract.country}
                changed={isChanged('country')}
                onChange={handleChange('country')}
              />
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h3" gutterBottom>
              {t('section.billing')}
            </Typography>
            <Divider sx={{ marginBottom: 1 }} />
            <Stack direction="column" spacing={0.75}>
              <LabelValueItem
                label={t('contractFields.employerGrant')}
                value={t('common:money', { money: updatedContract.employerGrant })}
                requestChangeTooltip={t('pleaseUseContactForm')}
              />
              <LabelValueItem
                label={t('contractFields.employeePremiumGross')}
                value={`+ ${t('common:money', { money: updatedContract.employeePremiumGross })}`}
                valuePostfix={
                  updatedContract.employeePremiumNet ? `(${t('netValue', { money: updatedContract.employeePremiumNet })})` : undefined
                }
                requestChangeTooltip={t('pleaseUseContactForm')}
              />
              <LabelValueItem
                label={t('contractFields.vwlConversion')}
                value={`+ ${t('common:money', { money: updatedContract.vwlConversion })}`}
                requestChangeTooltip={t('pleaseUseContactForm')}
              />
              <LabelValueItem label={t('contractFields.totalPremium')} value={`= ${t('common:money', { money: totalPremium })}`} />
              <LabelValueItem
                label={t('contractFields.paymentPeriod')}
                value={t(`paymentPeriod.${updatedContract.paymentPeriod}`)}
                valuePostfix={periodPremium ? `(${t('periodPremium', { money: periodPremium })})` : undefined}
              />
              <LabelValueItem
                label={t('contractFields.insuranceIBAN')}
                value={updatedContract.insuranceIBAN.match(/\w{2,4}/g)?.join(' ')}
              />
              <LabelValueItem label={t('contractFields.insuranceBIC')} value={resolvedIBAN?.bic} />
              <LabelValueItem label={t('contractFields.insuranceBankName')} value={resolvedIBAN?.bankName} />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Typography variant="h3" gutterBottom>
          {t('section.documents')}
        </Typography>
        <Divider sx={{ marginBottom: 1 }} />
        <ContractDocuments contract={contract} />
      </Grid>
    </Grid>
  );
};
