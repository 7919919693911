import { Grid } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel';

interface Props {
  groupSize?: number;
  spacing?: number;
  autoPlay?: boolean;
}

function* chunks<T>(arr: T[], n: number): Generator<T[], void> {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

/**
 * splits children into groups of size groupSize (default 3) before displaying them in the carousel
 */
export const GroupedCarousel: React.FC<React.PropsWithChildren<Props>> = ({ groupSize = 3, spacing = 1, autoPlay, children }) => {
  const carouselGroups = [...chunks(React.Children.toArray(children), groupSize)];

  return (
    <Carousel
      autoPlay={autoPlay || false}
      interval={7500}
      navButtonsAlwaysVisible
      animation={autoPlay ? 'fade' : 'slide'}
      fullHeightHover={false}
      navButtonsProps={{ className: 'nav-button' }}
      sx={{ flexShrink: 0, '& .nav-button': { opacity: 0.4, backgroundColor: 'black' } }}
    >
      {carouselGroups.map((group, groupIdx) => (
        <Grid key={groupIdx} container columns={groupSize} spacing={spacing}>
          {group.map((item, itemIdx) => (
            <Grid key={itemIdx} item xs={1}>
              {item}
            </Grid>
          ))}
        </Grid>
      ))}
    </Carousel>
  );
};
