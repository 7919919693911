import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar as MuiAppBar,
  Box,
  Breadcrumbs,
  Divider,
  IconButton,
  Link,
  Paper,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as NavLink } from 'react-router-dom';
import ContainerBox from '../../component/layout/ContainerBox';
import { spacings } from '../../theme';

export interface BreadcrumbLink {
  title: string;
  to: string;
}

interface Props {
  logo: React.ReactNode;
  /**
   * App bar menu
   */
  menu: React.ReactNode;
  /**
   * Title of sub page; should only be empty on homepage
   */
  title?: string;
  /**
   * Array of additional breadcrumb links; home will be included automatically
   */
  breadcrumbs?: readonly BreadcrumbLink[];
  /**
   * White panel (paper) below breadcrumb bar
   */
  topPanel?: React.ReactNode;
  onOpenDrawer?: () => void;
  open?: boolean;
}

export const NavbarLayout: React.FC<React.PropsWithChildren<Props>> = ({
  logo,
  menu,
  title,
  breadcrumbs,
  topPanel,
  children,
  onOpenDrawer,
  open,
}) => {
  const { t } = useTranslation('navigationModule');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

  const home = (
    <span>
      <HomeIcon sx={{ verticalAlign: 'middle', fontSize: '1rem' }} /> {t('home')}
    </span>
  );

  return (
    <Box>
      <MuiAppBar position="sticky" color="default" elevation={0} sx={{ maxWidth: '100%' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: spacings.maxWidth, width: '100%', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 1 }}>
              {onOpenDrawer && isMobile && (
                <IconButton
                  color="primary"
                  title={t('openDrawerMenu')}
                  aria-expanded={open}
                  onClick={onOpenDrawer}
                  data-test-id="main-menu-button"
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
              )}
              {onOpenDrawer && isMobile && (
                <Divider
                  orientation="vertical"
                  sx={{ height: 35, verticalAlign: 'middle', display: 'inline-block', marginRight: 2, marginLeft: 0.8 }}
                />
              )}
              <NavLink
                to="/"
                style={{ textDecoration: 'none', color: 'inherit', whiteSpace: 'nowrap' }}
                title={t('toHome')}
                aria-label={t('toHome')}
              >
                {logo}
              </NavLink>
            </Box>
            {menu}
          </Box>
        </Toolbar>
        <Breadcrumbs
          data-test-id="main-breadcrumbs"
          aria-label="breadcrumb"
          sx={{ display: 'flex', '& ol': { flexWrap: 'nowrap' }, '& li': { maxWidth: '90%' } }}
        >
          {title ? (
            <Link component={NavLink} underline="hover" color="inherit" to="/">
              {home}
            </Link>
          ) : (
            <span>{home}</span>
          )}
          {breadcrumbs?.map(breadcrumb => (
            <Link key={breadcrumb.to} component={NavLink} underline="hover" color="inherit" to={breadcrumb.to}>
              {breadcrumb.title}
            </Link>
          ))}
          {title && (
            <Typography variant="body2" lineHeight={1.5} noWrap>
              {title}
            </Typography>
          )}
        </Breadcrumbs>
      </MuiAppBar>
      {topPanel && (
        <Paper sx={{ borderRadius: 0, paddingX: spacings.main, paddingY: 1.5 }}>
          <ContainerBox>{topPanel}</ContainerBox>
        </Paper>
      )}
      <main
        style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(3), paddingLeft: spacings.main, paddingRight: spacings.main }}
      >
        <ContainerBox>{children}</ContainerBox>
      </main>
    </Box>
  );
};
