import { InputAdornment } from '@mui/material';
import {
  BenefitActivationDTO,
  BenefitDTOBenefitEnum,
  BenefitTemplateItemDTO,
  CreateBenefitActivationDTO,
  MobilityBenefitOptionsDTOTaxationTypeEnum,
  UpdateBenefitActivationDTO,
} from 'probonio-shared-ui/api';
import { RadioControl } from 'probonio-shared-ui/component/form';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldControl } from '../../../component/form';
import { ActivationDialogExtensions } from '../ActivationDialog';

interface MobilityBenefitOptions {
  monthlyLimit: number;
  taxationType: MobilityBenefitOptionsDTOTaxationTypeEnum;
}

const InlineField: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { control } = useFormContext();

  return (
    <TextFieldControl
      InputProps={{
        endAdornment: <InputAdornment position="end">€</InputAdornment>,
      }}
      inputProps={{ step: '5' }}
      rules={{
        required: true,
        min: 5,
        max: 1000,
      }}
      type="number"
      control={control}
      variant="outlined"
      name="benefitOptions.monthlyLimit"
      label={t('MOBILITY.fields.monthlyLimit')}
      margin="dense"
      fullWidth
    />
  );
};

const Fields: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { control } = useFormContext();

  return (
    <RadioControl
      control={control}
      name="benefitOptions.taxationType"
      options={[
        {
          label: t('MOBILITY.fields.taxationType.TAXABLE'),
          value: MobilityBenefitOptionsDTOTaxationTypeEnum.Taxable,
        },
        {
          label: t('MOBILITY.fields.taxationType.TAX_FREE'),
          value: MobilityBenefitOptionsDTOTaxationTypeEnum.TaxFree,
        },
      ]}
    />
  );
};

export function useMobilityDialogExtensions(): ActivationDialogExtensions<MobilityBenefitOptions> {
  const handleResetBenefitOptions = useCallback(
    (selectedActivation?: BenefitActivationDTO | BenefitTemplateItemDTO): MobilityBenefitOptions => {
      return {
        monthlyLimit: selectedActivation?.mobilityOptions ? selectedActivation.mobilityOptions.monthlyLimit / 100 : 50,
        taxationType: selectedActivation?.mobilityOptions?.taxationType || MobilityBenefitOptionsDTOTaxationTypeEnum.Taxable,
      };
    },
    [],
  );

  const mapMobilityOptions = useCallback(
    (benefitOptions: MobilityBenefitOptions) => ({
      monthlyLimit: Math.round(benefitOptions.monthlyLimit * 100),
      taxationType: benefitOptions.taxationType,
    }),
    [],
  );
  const handleCreateActivation = useCallback<ActivationDialogExtensions<MobilityBenefitOptions>['onCreateActivation']>(
    async (baseValues, benefitOptions, saveActivation) => {
      const create: CreateBenefitActivationDTO = {
        ...baseValues,
        benefit: BenefitDTOBenefitEnum.Mobility,
        mobilityOptions: mapMobilityOptions(benefitOptions),
      };
      await saveActivation(create);
    },
    [mapMobilityOptions],
  );
  const handleUpdateActivation = useCallback<NonNullable<ActivationDialogExtensions<MobilityBenefitOptions>['onUpdateActivation']>>(
    async (baseValues, benefitOptions, saveActivation) => {
      const update: UpdateBenefitActivationDTO = {
        ...baseValues,
        mobilityOptions: mapMobilityOptions(benefitOptions),
      };
      await saveActivation(update);
    },
    [mapMobilityOptions],
  );

  return useMemo(
    () => ({
      InlineField,
      Fields,
      onResetBenefitOptions: handleResetBenefitOptions,
      onCreateActivation: handleCreateActivation,
      onUpdateActivation: handleUpdateActivation,
    }),
    [handleCreateActivation, handleResetBenefitOptions, handleUpdateActivation],
  );
}
