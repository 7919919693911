import PeopleIcon from '@mui/icons-material/People';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../redux/hooks';
import { TutorialStepDefinition } from '../TutorialStep';

export function useConfigureBenefitsStep(): TutorialStepDefinition {
  const { t } = useTranslation('tutorialModule');
  const tenantId = useAppSelector(state => state.me.currentTenantId);
  const [queryEnabled, setQueryEnabled] = useState(true);

  const { data: benefitActivations } = useTenantQuery(
    ['benefitActivations', { page: 0, pageSize: 0 }, apis.benefits.listTenantBenefitActivations.name],
    tenantId => apis.benefits.listTenantBenefitActivations({ tenantId, page: 0, pageSize: 0 }).then(res => res.data),
    { enabled: queryEnabled },
  );

  const loading = !benefitActivations;
  const completed = !!benefitActivations?.totalCount;

  useEffect(() => {
    if (completed) {
      setQueryEnabled(false);
    }
  }, [completed]);
  useEffect(() => {
    if (tenantId) {
      setQueryEnabled(true);
    }
  }, [tenantId]);

  return useMemo(
    () => ({
      ...t('steps.configureBenefits', { returnObjects: true }),
      path: '/employees',
      buttonIcon: PeopleIcon,
      loading,
      completed,
    }),
    [completed, loading, t],
  );
}
