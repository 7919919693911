import { Box } from '@mui/material';
import { MRT_Row } from 'material-react-table';
import { BikeContractDTO } from 'probonio-shared-ui/api';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteScrollReactTable } from '../../../component/table/InfiniteScrollReactTable';
import { useLoadMore } from '../../../component/table/useLoadMore';
import { BikeContractActions } from './BikeContractActions';
import { useBikeContractColumns } from './bikeContractColumns';
import { useInfiniteBikeContracts } from './useBikeContracts';
import { useNavigateWithPrevPath } from '../../../util/useNavigateWithPrevPath';

export const BikeContractsTable: React.FC = () => {
  const { t } = useTranslation('bikeModule');
  const navigate = useNavigateWithPrevPath();
  const columns = useBikeContractColumns();

  const queryResult = useInfiniteBikeContracts();

  const loadMoreProps = useLoadMore(queryResult, 'results');

  const handleRowProps = useCallback(
    ({ row }: { row: MRT_Row<BikeContractDTO> }) => ({
      onClick: () => {
        navigate(`/benefits/bike/contracts/${row.original.employeeId}`);
      },
      sx: { cursor: 'pointer' },
    }),
    [navigate],
  );

  return (
    <Box data-test-id="bike-contracts-table">
      <InfiniteScrollReactTable
        {...loadMoreProps}
        columns={columns}
        enableSorting={false}
        enablePagination={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableColumnActions={false}
        enableSortingRemoval={false}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={BikeContractActions}
        localization={{ noRecordsToDisplay: t('contractsTable.noResults') }}
        muiTableBodyRowProps={handleRowProps}
      />
    </Box>
  );
};
