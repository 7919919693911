import { Box, Typography } from '@mui/material';
import { BikeleasingConditionDTO } from 'probonio-shared-ui/api';
import { useTranslation } from 'react-i18next';

const ConditionField: React.FC<{ label: string; value: string; dataTestId?: string }> = ({ label, value, dataTestId }) => {
  return (
    <Box display="flex" sx={{ flexGrow: 1 }} justifyContent="space-between">
      <Typography mr={5} variant="body2" color="text.secondary" fontWeight="bold">
        {label}
      </Typography>
      <Typography variant="body2" fontWeight="bold" data-test-id={dataTestId}>
        {value}
      </Typography>
    </Box>
  );
};

export const SelectedBikeleasingCondition: React.FC<{ bikeleasingCondition?: BikeleasingConditionDTO }> = ({ bikeleasingCondition }) => {
  const { t } = useTranslation('benefitsModule');
  if (!bikeleasingCondition) {
    return;
  }
  return (
    <>
      <ConditionField value={`${bikeleasingCondition.maxNumberOfBikesPerEmployee}`} label={t('BIKELEASING.maxNumberOfBikesPerEmployee')} />
      <ConditionField value={`${bikeleasingCondition.leasingDurationInMonths}`} label={t('BIKELEASING.leasingDurationInMonths')} />
      <ConditionField value={bikeleasingCondition.allowedBikeTypes.join(', ')} label={t('BIKELEASING.allowedBikeTypes')} />

      <ConditionField
        value={t('common:money', { money: bikeleasingCondition.priceRestrictions?.minPurchasePrice })}
        label={t('BIKELEASING.minPurchasePrice')}
      />
      <ConditionField
        value={t('common:money', { money: bikeleasingCondition.priceRestrictions?.maxPurchasePrice })}
        label={t('BIKELEASING.maxPurchasePrice')}
      />
      <ConditionField
        value={
          bikeleasingCondition.priceRestrictions?.maxTotalPurchasePriceForAllBikes
            ? t('common:money', { money: bikeleasingCondition.priceRestrictions?.maxTotalPurchasePriceForAllBikes })
            : '-'
        }
        label={t('BIKELEASING.priceRestrictions.maxTotalPurchasePriceForAllBikes')}
      />
      {bikeleasingCondition.employerContribution?.absolute && (
        <ConditionField
          value={t('common:money', { money: bikeleasingCondition.employerContribution.absolute })}
          label={t('BIKELEASING.employerContribution')}
        />
      )}
      {bikeleasingCondition.employerContribution?.percentage && (
        <ConditionField value={`${bikeleasingCondition.employerContribution.percentage}`} label={t('BIKELEASING.employerContribution')} />
      )}
    </>
  );
};
