import { Divider, useTheme } from '@mui/material';
import { Grid, InputAdornment, Typography } from '@mui/material';
import { BenefitDTOBenefitEnum, BenefitExpensesDTO } from 'probonio-shared-ui/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldControl } from '../../../../component/form';
import { IncreaseBudgetValues } from './CouponsIncreaseBudgetDialog';
import { PaymentMethodInfo } from './PaymentMethodInfo';

interface Props {
  expenses?: BenefitExpensesDTO;
}
const BUDGET_MIN_VALUE = 50;

export const ManualIncreaseBudgetDialogContent: React.FC<Props> = ({ expenses }) => {
  const { t, i18n } = useTranslation('couponsModule');
  const theme = useTheme();
  const { tenant } = useTenant();
  const currentBudget = tenant?.couponBudget ? tenant.couponBudget : 0;

  const { formState, watch, control, unregister } = useFormContext<IncreaseBudgetValues>();

  const newBudget = formState.isValid ? i18n.format(currentBudget + (watch('amount') || BUDGET_MIN_VALUE) * 100, 'euro') : '-';

  const hasBothBenefits =
    tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Coupons) && tenant?.activeBenefits?.includes(BenefitDTOBenefitEnum.Gifts);

  useEffect(() => {
    // Unregister values, which got registered while mounting
    // the form bellow, to remove the form validation for these fields on tab change.
    return () => {
      unregister('amount');
    };
  }, [unregister]);

  return (
    <Grid container>
      <Grid item xs={6} marginTop={0.5}>
        <TextFieldControl
          defaultValue={BUDGET_MIN_VALUE}
          control={control}
          name="amount"
          label={t('budgetPanelIncreaseBudgetDialog.tabs.manual.amount')}
          helperText={t('budgetPanelIncreaseBudgetDialog.tabs.manual.amountHelperText', { money: BUDGET_MIN_VALUE * 100 })}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          type="number"
          rules={{
            required: t('common:inputRequired') as string,
            min: BUDGET_MIN_VALUE,
            pattern: { value: /^\d+?$/, message: t('budgetPanelIncreaseBudgetDialog.tabs.manual.integerRequired') },
          }}
          sx={{ marginBottom: 2.5 }}
        />
        <PaymentMethodInfo />
      </Grid>
      <Grid item xs={6} container justifyContent="space-between">
        <Grid item xs={6}>
          <Typography color={theme.palette.text.secondary}>{t('budgetPanelIncreaseBudgetDialog.tabs.manual.currentBudget')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography textAlign="right" color={theme.palette.text.secondary}>
            {i18n.format(currentBudget, 'euro')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontWeight="700" color={theme.palette.text.secondary}>
            {t('budgetPanelIncreaseBudgetDialog.tabs.manual.newBudget')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography textAlign="right" fontWeight="700" color={theme.palette.text.secondary}>
            {newBudget}
          </Typography>
        </Grid>
        <Grid item xs={6} marginTop={2}>
          <Typography color={theme.palette.text.secondary} fontStyle="italic">
            {t('budgetPanelIncreaseBudgetDialog.tabs.manual.expectedExpenses')}
          </Typography>
        </Grid>
        <Grid item xs={6} marginTop={2}>
          <Typography textAlign="right" color={theme.palette.text.secondary} fontStyle="italic">
            {i18n.format(expenses?.expectedExpensesMonth || 0, 'euro')}
          </Typography>
        </Grid>
        {hasBothBenefits && (
          <>
            <Grid item xs={12} marginY={0.1}>
              <Divider />
            </Grid>
            <Grid item xs={8}>
              <Typography color={theme.palette.text.secondary} fontStyle="italic" marginLeft={1}>
                •&nbsp;&nbsp;{t('common:benefit.COUPONS')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography textAlign="right" color={theme.palette.text.secondary} fontStyle="italic">
                {i18n.format(expenses?.benefits.COUPONS?.expectedExpensesMonth || 0, 'euro')}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography color={theme.palette.text.secondary} fontStyle="italic" marginLeft={1}>
                •&nbsp;&nbsp;{t('common:benefit.GIFTS')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography textAlign="right" color={theme.palette.text.secondary} fontStyle="italic">
                {i18n.format(expenses?.benefits.GIFTS?.expectedExpensesMonth || 0, 'euro')}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
