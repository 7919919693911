import { Box, Grid, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogState } from '../../../component/dialog';
import { ConfirmationModal } from '../../../component/dialog/ConfirmationModal';
import { DatePickerControl } from '../../../component/form';

interface Props {
  dialogState: DialogState;
  handleConfirm: (month?: DateTime) => void;
  loading: boolean;
  message: string;
  initialMonth?: DateTime;
}

interface FormValues {
  month?: Date;
}

const ReportDownloadDialog: React.FC<Props> = ({ dialogState, handleConfirm, loading, message, initialMonth }) => {
  const { t } = useTranslation('benefitsModule', { keyPrefix: 'report.reportDownloadDialog' });
  const theme = useTheme();
  const { control, getValues, reset } = useForm<FormValues>();

  useEffect(() => {
    if (dialogState.isOpen) {
      reset({ month: initialMonth?.toJSDate() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogState.isOpen]);

  const handleSubmit = useCallback(() => {
    const month = getValues('month');
    handleConfirm(month && DateTime.fromJSDate(month));
  }, [getValues, handleConfirm]);

  return (
    <ConfirmationModal
      dialogState={dialogState}
      title={t('title')}
      confirmButtonTitle={t('actionSuccess')}
      onConfirm={handleSubmit}
      closeOnConfirm={false}
      loading={loading}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          py: 1,
          px: 2,
          mb: 2,
          maxWidth: '700px',
          textAlign: 'center',
          borderRadius: '8px',
        }}
      >
        <Typography color={theme.palette.text.secondary}>{message}</Typography>
      </Box>
      <Grid sx={{ marginBottom: 2 }} justifyContent="center" container>
        <Grid item md={6}>
          <Typography color="text.secondary" gutterBottom>
            {t('exportMonth')}
          </Typography>
          <DatePickerControl
            monthYearPicker
            showNextPreviousToggle
            control={control}
            name="month"
            label=""
            helperText={t('exportMonthHelper')}
            minDate={DateTime.now().minus({ year: 5 }).toJSDate()}
            maxDate={DateTime.now().endOf('month').toJSDate()}
          />
        </Grid>
      </Grid>
    </ConfirmationModal>
  );
};

export default ReportDownloadDialog;
