import { IconButton } from '@mui/material';
import { SnackbarProvider as NSnackbarProvider, SnackbarKey } from 'notistack';
import { useCallback, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  children: React.ReactElement;
}

export const SnackbarProvider: React.FC<Props> = ({ children }) => {
  const snackbarRef = useRef<NSnackbarProvider>(null);

  const onClickDismiss = useCallback(
    (key: SnackbarKey) => () => {
      snackbarRef.current?.closeSnackbar(key);
    },
    [],
  );

  const action = useCallback(
    (key: SnackbarKey) => (
      <IconButton onClick={onClickDismiss(key)}>
        <CloseIcon />
      </IconButton>
    ),
    [onClickDismiss],
  );

  return (
    <NSnackbarProvider ref={snackbarRef} maxSnack={3} action={action}>
      {children}
    </NSnackbarProvider>
  );
};
