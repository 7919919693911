import { LinearProgress, Skeleton } from '@mui/material';
import { CUSTOM_BENEFIT_ICONS } from 'probonio-shared-ui/component/icon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { CustomBenefitCreator, useCustomBenefitCreatorState } from '../../module/benefits/customBenefit';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';
import { DeleteCustomBenefitButton } from '../../module/benefits/customBenefit/DeleteCustomBenefitButton';
import { useTranslation } from 'react-i18next';

export const CustomBenefitCreatorPage: React.FC = () => {
  const { t } = useTranslation('customBenefitsModule');
  const customBenefitCreatorState = useCustomBenefitCreatorState();

  if (!customBenefitCreatorState?.currentCustomBenefitTemplate) {
    return (
      <AuthorizedLayout>
        <LinearProgress />
        <Skeleton animation="wave" height="80vh" />
      </AuthorizedLayout>
    );
  }

  const isUpdate = !!customBenefitCreatorState.currentCustomBenefitTemplate.customBenefitId;
  const deleteButton = isUpdate ? (
    <DeleteCustomBenefitButton
      customBenefitId={customBenefitCreatorState.currentCustomBenefitTemplate.customBenefitId}
      customBenefitTitle={customBenefitCreatorState.currentCustomBenefitTemplate.customBenefit?.title}
    />
  ) : (
    <></>
  );

  return (
    <AuthorizedLayout
      title={isUpdate ? t('subTitle') : customBenefitCreatorState.currentCustomBenefitTemplate.benefit.title}
      breadcrumbs={
        isUpdate
          ? [
              {
                title: customBenefitCreatorState.currentCustomBenefitTemplate.benefit.title,
                to: `/benefits/custom/${customBenefitCreatorState.currentCustomBenefitTemplate.customBenefitId}`,
              },
            ]
          : undefined
      }
    >
      <BenefitSubPage
        navigateBackTo={
          isUpdate ? `/benefits/custom/${customBenefitCreatorState.currentCustomBenefitTemplate.customBenefitId}` : '/benefits/custom'
        }
        icon={CUSTOM_BENEFIT_ICONS[customBenefitCreatorState.currentCustomBenefitTemplate.benefit.iconValue]}
        title={customBenefitCreatorState.currentCustomBenefitTemplate.benefit.title}
        subTitle={customBenefitCreatorState.subtitle}
        menu={deleteButton}
        showBack
      >
        <CustomBenefitCreator customBenefitCreatorState={customBenefitCreatorState} />
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};
