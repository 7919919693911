import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PercentIcon from '@mui/icons-material/Percent';
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { CopyToClipboardButton } from 'probonio-shared-ui/component/button/CopyToClipboardButton';
import { apis } from 'probonio-shared-ui/module/api';
import { loadMe } from 'probonio-shared-ui/module/me';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../component/dialog';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { formatRegistrationCode } from '../userManagement/AddUserDialog/AddUserCode';

interface Props {
  dialogState: DialogState;
}

export const ReferralDialog: React.FC<Props> = ({ dialogState }) => {
  const { t } = useTranslation('navigationModule');
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(state => state.me.user);
  const theme = useTheme();

  const mutation = useMutation({
    mutationFn: async () => {
      await apis.user.createReferralCode({ userId: currentUser!.id });
    },

    onSuccess: async () => {
      await dispatch(loadMe(true));
    },
  });

  useEffect(() => {
    if (dialogState.isOpen && !currentUser?.referralCode) {
      mutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.referralCode, dialogState.isOpen]);

  return (
    <BasicDialog
      dataTestId="referral-modal"
      dialogState={dialogState}
      maxWidth="xs"
      fullWidth
      title={t('referralCode.dialogTitle')}
      content={
        <Grid pt={1} gap={1} display="flex" flexDirection="column">
          <HandshakeIcon sx={{ alignSelf: 'center', height: 80, width: 80, color: theme.palette.primary.main }} />
          <Typography variant="h4" fontSize={20} textAlign="center" pt={2} pb={1}>
            {t('referralCode.howItWorks')}
          </Typography>
          <Typography color="text.secondary" variant="subtitle2" textAlign="center">
            {t('referralCode.dialogDescription')}
          </Typography>
          <List sx={{ alignSelf: 'center', paddingX: 6 }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  <CardGiftcardIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="h2">{t('referralCode.bonusOneTitle')}</Typography>}
                secondary={<Typography variant="subtitle2">{t('referralCode.bonusOneDesc')}</Typography>}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  <PercentIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="h2">{t('referralCode.bonusTwoTitle')}</Typography>}
                secondary={<Typography variant="subtitle2">{t('referralCode.bonusTwoDesc')}</Typography>}
              />
            </ListItem>
          </List>
          <Typography variant="subtitle2" color="text.secondary" textAlign="center" pb={0.5}>
            {t('referralCode.dialogHowTo')}
          </Typography>
          <TextField
            data-test-id="referral-code-realcode"
            variant="outlined"
            fullWidth
            label={t('referralCode.code')}
            value={formatRegistrationCode(currentUser?.referralCode) || ''}
            InputProps={{
              readOnly: true,
              endAdornment: <CopyToClipboardButton edge="end" value={formatRegistrationCode(currentUser?.referralCode)} />,
            }}
          />
          <Typography variant="subtitle2" color="text.secondary" textAlign="center">
            {t('referralCode.dialogFooter')}
          </Typography>
        </Grid>
      }
    />
  );
};
