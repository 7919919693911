import { Grid, InputAdornment } from '@mui/material';
import {
  BenefitActivationDTO,
  BenefitDTOBenefitEnum,
  BenefitTemplateItemDTO,
  CreateBenefitActivationDTO,
  CustomBenefitDTO,
  CustomBenefitOptionsDTO,
  UpdateBenefitActivationDTO,
} from 'probonio-shared-ui/api';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TextFieldControl } from '../../../component/form';
import { ActivationDialogExtensions } from '../ActivationDialog';
import { NonCashLimitWarning } from '../ActivationDialog/NonCashLimitWarning';

interface CustomBenefitOptions {
  monthlyGrant?: number;
  supplier?: string;
  comment?: string;
  relevantForNonCash?: boolean;
  customBenefitId?: string;
}

const Header: React.FC = () => {
  const { watch } = useFormContext();
  const { employeeId } = useParams();
  const benefitOptions: CustomBenefitOptionsDTO = watch('benefitOptions');

  return (
    <>
      {benefitOptions.relevantForNonCash && (
        <NonCashLimitWarning
          employeeId={employeeId}
          benefit={BenefitDTOBenefitEnum.Custom}
          customBenefitId={benefitOptions.customBenefitId}
          monthlyBudget={benefitOptions.monthlyGrant! * 100}
        />
      )}
    </>
  );
};

const Fields: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { control } = useFormContext();

  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={1} marginBottom={1}>
        <Grid item xs={12}>
          <TextFieldControl
            control={control}
            variant="outlined"
            name="benefitOptions.supplier"
            label={t('CUSTOM.fields.supplier')}
            margin="dense"
            fullWidth
            sx={{ mt: 0 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldControl
            control={control}
            multiline
            variant="outlined"
            name="benefitOptions.comment"
            label={t('CUSTOM.fields.comment')}
            margin="dense"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

const InlineField: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { control } = useFormContext();

  return (
    <TextFieldControl
      InputProps={{
        endAdornment: <InputAdornment position="end">€</InputAdornment>,
      }}
      inputProps={{ step: '5' }}
      rules={{
        min: 0,
        max: 1000,
      }}
      type="number"
      control={control}
      variant="outlined"
      name="benefitOptions.monthlyGrant"
      label={t('CUSTOM.fields.monthlyGrant')}
      margin="dense"
      fullWidth
    />
  );
};

export function useCustomBenefitDialogExtensions(customBenefit: CustomBenefitDTO): ActivationDialogExtensions<CustomBenefitOptions> {
  const handleResetBenefitOptions = useCallback(
    (selectedActivation?: BenefitActivationDTO | BenefitTemplateItemDTO): CustomBenefitOptions => {
      const monthlyGrant = selectedActivation?.customBenefitOptions?.monthlyGrant ?? customBenefit.defaultMonthlyGrant;
      const relevantForNonCash = selectedActivation?.customBenefitOptions?.relevantForNonCash ?? customBenefit.relevantForNonCash;
      return {
        monthlyGrant: monthlyGrant === undefined ? undefined : monthlyGrant / 100,
        supplier: selectedActivation?.customBenefitOptions?.supplier || '',
        comment: selectedActivation?.customBenefitOptions?.comment || '',
        relevantForNonCash: relevantForNonCash ?? false,
        customBenefitId: customBenefit.id,
      };
    },
    [customBenefit],
  );

  const handleCreateActivation = useCallback<ActivationDialogExtensions<CustomBenefitOptions>['onCreateActivation']>(
    async (baseValues, benefitOptions, saveActivation) => {
      const create: CreateBenefitActivationDTO = {
        ...baseValues,
        benefit: BenefitDTOBenefitEnum.Custom,
        customBenefitOptions: {
          customBenefitId: customBenefit.id,
          monthlyGrant: benefitOptions.monthlyGrant === undefined ? undefined : Math.round(benefitOptions.monthlyGrant * 100),
          supplier: benefitOptions.supplier || undefined,
          comment: benefitOptions.comment || undefined,
          relevantForNonCash: benefitOptions.relevantForNonCash ?? false,
        },
      };
      await saveActivation(create);
    },
    [customBenefit.id],
  );
  const handleUpdateActivation = useCallback<NonNullable<ActivationDialogExtensions<CustomBenefitOptions>['onUpdateActivation']>>(
    async (baseValues, benefitOptions, saveActivation) => {
      const update: UpdateBenefitActivationDTO = {
        ...baseValues,
        customBenefitOptions: {
          monthlyGrant: benefitOptions.monthlyGrant === undefined ? null : Math.round(benefitOptions.monthlyGrant * 100),
          supplier: benefitOptions.supplier || null,
          comment: benefitOptions.comment || null,
          relevantForNonCash: benefitOptions.relevantForNonCash ?? false,
        },
      };
      await saveActivation(update);
    },
    [],
  );

  return useMemo(
    () => ({
      Header,
      Fields,
      InlineField,
      onResetBenefitOptions: handleResetBenefitOptions,
      onCreateActivation: handleCreateActivation,
      onUpdateActivation: handleUpdateActivation,
    }),
    [handleCreateActivation, handleResetBenefitOptions, handleUpdateActivation],
  );
}
