import DownloadIcon from '@mui/icons-material/Download';
import { Button, Grid, Stack, TextField, Tooltip } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { IBANResolutionDTO } from 'probonio-shared-ui/api';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { apis, downloadResponse } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenant, useTenantID } from 'probonio-shared-ui/module/me';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isIBAN from 'validator/es/lib/isIBAN';
import { TextFieldControl } from '../../component/form';
import { calculateBlobSHA256, storeHash } from 'probonio-shared-ui/utils/hashStorage';
import { SepaStepHeader } from './SepaStepHeader';

interface Props {
  onNext: () => void;
}

interface FormFields {
  iban: string;
}

const SUPPORTED_IBAN_COUNTRIES = ['AT', 'BE', 'DE', 'ES', 'FR', 'LU', 'NL'];

export const SepaMandateIBANForm: React.FC<Props> = ({ onNext }) => {
  const { t } = useTranslation('tenantModule');
  const { tenant, isLoading, invalidateTenant } = useTenant();
  const { control, reset, handleSubmit, formState } = useForm<FormFields>({ mode: 'all' });
  const withMessage = useWithMessage();
  const getTenantId = useTenantID();
  const [bankInfo, setBankInfo] = useState<IBANResolutionDTO>();

  const validateIBAN = useCallback(
    async (value?: string) => {
      let valid = value && isIBAN(value) && SUPPORTED_IBAN_COUNTRIES.includes(value?.substring(0, 2) || '');
      if (valid) {
        try {
          const bankInfo = await apis.sepa.resolveIBAN({ iban: value!.replace(/[^A-Z0-9]/g, '') }).then(res => res.data);
          setBankInfo(bankInfo);
        } catch (err) {
          if (err instanceof AxiosError && err.response?.status === 400) {
            valid = false;
          } else {
            withMessage(err as Error);
          }
        }
      }
      if (!valid) {
        setBankInfo(undefined);
        return t('paymentMethods.sepaStepper.invalidIban', { countries: SUPPORTED_IBAN_COUNTRIES.join(', ') }) as string;
      }
      return true;
    },
    [t, withMessage],
  );

  useEffect(() => {
    if (tenant) {
      reset({ iban: tenant.iban || '' });
      if (tenant.iban) {
        void validateIBAN(tenant.iban);
      }
    }
  }, [tenant, reset, validateIBAN]);

  const mutation = useMutation({
    mutationFn: async (values: FormFields) => {
      await apis.sepa.updateTenantSepaMandate({
        tenantId: tenant!.id,
        updateTenantSepaConfigDTO: {
          sepa: true,
          iban: values.iban.replace(/[^A-Z0-9]/g, ''),
        },
      });
      const response = await apis.sepa.generateSepaMandateTemplate({ tenantId: getTenantId() }, { responseType: 'blob' });
      const hash = await calculateBlobSHA256(response.data);
      storeHash('sepaTemplate', hash);
      downloadResponse(response, 'SEPA Mandate.pdf');
    },

    onSuccess: () => {
      invalidateTenant();
    },
  });

  const handleSave = useCallback(
    (values: FormFields) => {
      mutation.mutate(values);
    },
    [mutation],
  );

  return (
    <>
      <SepaStepHeader
        title={t('paymentMethods.sepaStepper.step.bankInfo.header')}
        text={t('paymentMethods.sepaStepper.step.bankInfo.info')}
        complete={tenant?.sepa && !!tenant?.iban && !formState.isDirty}
      />
      <form onSubmit={handleSubmit(handleSave)}>
        <Grid container marginBottom={1}>
          <Grid item container xs={8} lg={6} columnSpacing={2.5} rowSpacing={2}>
            <Grid item xs={6}>
              <TextFieldControl
                control={control}
                name="iban"
                label={t('paymentMethods.sepaStepper.step.bankInfo.field.iban')}
                fullWidth
                size="small"
                helperText={t('paymentMethods.sepaStepper.step.bankInfo.field.ibanHelper', {
                  countries: SUPPORTED_IBAN_COUNTRIES.join(', '),
                })}
                rules={{
                  required: true,
                  validate: {
                    iban: validateIBAN,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                type="submit"
                startIcon={<DownloadIcon />}
                size="small"
                loading={mutation.isPending}
                fullWidth
                data-test-id="sepa-download-template"
              >
                {t('paymentMethods.sepaStepper.step.bankInfo.downloadButton')}
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                label={t('paymentMethods.sepaStepper.step.bankInfo.field.bic')}
                fullWidth
                size="small"
                value={bankInfo?.bic || ''}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                label={t('paymentMethods.sepaStepper.step.bankInfo.field.bankName')}
                fullWidth
                size="small"
                value={bankInfo?.bankName || ''}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <Button variant="text" size="small" disabled>
          {t('common:buttons.back')}
        </Button>
        <Tooltip
          arrow
          placement="top"
          title={t('paymentMethods.sepaStepper.step.bankInfo.pleaseDownload')}
          disableHoverListener={!formState.isDirty && tenant?.sepa}
        >
          <span>
            <Button
              size="small"
              disabled={isLoading || mutation.isPending || formState.isDirty || !tenant?.sepa}
              onClick={onNext}
              data-test-id="sepa-button-next"
            >
              {t('common:buttons.next')}
            </Button>
          </span>
        </Tooltip>
      </Stack>
    </>
  );
};
