import { InputAdornment, Typography } from '@mui/material';
import { SwitchControl } from 'probonio-shared-ui/component/form';
import { Control, FormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldControl } from '../../../../../../component/form';
import { VerticalBox } from '../../../../../../component/layout/BoxLayout';
import { FormValues } from '../../../useCustomBenefitCreator';
import { VisualisationContentValidation } from './VisualisationContentValidation';

interface Props {
  setCanContinue: (newValue: boolean) => void;
  control: Control<FormValues>;
  formState: FormState<FormValues>;
}

export const VisualisationContent: React.FC<Props> = ({ setCanContinue, control, formState }) => {
  const { t } = useTranslation('customBenefitsModule');
  const showInVisualisation = useWatch({ control, name: 'showInVisualisation' });

  return (
    <>
      <VerticalBox gap={3}>
        <div>
          <Typography variant="h2">{t('customBenefitCreator.content.visualisationStep.title')}</Typography>
          <Typography variant="caption">{t('customBenefitCreator.content.visualisationStep.description')}</Typography>
        </div>
        <div>
          <SwitchControl
            data-test-id="custom-benefit-creator-page-visualisation-switch"
            label={t('customBenefitCreator.content.visualisationStep.switch')}
            name="showInVisualisation"
            control={control}
          />
          <br />
          <Typography variant="caption">{t('customBenefitCreator.content.visualisationStep.switchHelperText')}</Typography>
        </div>
        <div>
          <SwitchControl
            data-test-id="custom-benefit-creator-page-noncash-switch"
            label={t('customBenefitCreator.content.summaryStep.nonCashRelevant')}
            name="relevantForNonCash"
            control={control}
          />
          <br />
          <Typography variant="caption">{t('customBenefitCreator.content.summaryStep.nonCashRelevantHelperText')}</Typography>
        </div>
        <div>
          <TextFieldControl
            data-test-id="custom-benefit-creator-page-amount"
            type="number"
            control={control}
            style={{ minWidth: 200 }}
            name="defaultMonthlyGrant"
            label={t('customBenefitCreator.content.visualisationStep.inputTitle')}
            rules={{
              required: true,
              max: 999,
              min: showInVisualisation ? 1 : 0,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <br />
          <Typography variant="caption">{t('customBenefitCreator.content.visualisationStep.inputHelperText')}</Typography>
        </div>
      </VerticalBox>
      <VisualisationContentValidation control={control} formState={formState} setCanContinue={setCanContinue} />
    </>
  );
};
