import { Alert, Grid, InputAdornment, TextField } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  BenefitActivationDTO,
  BenefitDTOBenefitEnum,
  BenefitTemplateItemDTO,
  CreateBenefitActivationDTO,
  EmployeeDTOCivilStatusEnum,
  UpdateBenefitActivationDTO,
  UpdateEmployeeDTO,
} from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback, useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectControl } from '../../../component/form';
import ProfileEmployeeContext from '../../userManagement/UserProfile/activations/hooks/useProfileEmployee';
import { ActivationDialogExtensions } from '../ActivationDialog';
import { useRecreationMaxBudget } from './useRecreationMaxBudget';

interface RecreationBenefitOptions {
  civilStatus: EmployeeDTOCivilStatusEnum;
  numberOfKids: number;
}

const Fields: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { tenant } = useTenant();
  const { control, watch } = useFormContext();

  const profileContext = useContext(ProfileEmployeeContext);
  const employee = profileContext?.employee;

  const civilStatus = watch('benefitOptions.civilStatus');
  const numberOfKids = watch('benefitOptions.numberOfKids');

  const maxBudget = useRecreationMaxBudget(civilStatus, numberOfKids);

  return (
    <>
      {employee && (
        <Grid container spacing={1} marginBottom={2}>
          <Grid item xs={4}>
            <SelectControl
              control={control}
              name="benefitOptions.civilStatus"
              label={t('RECREATION.fields.civilStatus')}
              margin="dense"
              fullWidth
              options={Object.values(EmployeeDTOCivilStatusEnum).map(value => ({ label: t(`common:civilStatus.${value}`), value }))}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectControl
              control={control}
              name="benefitOptions.numberOfKids"
              label={t('RECREATION.fields.numberOfKids')}
              margin="dense"
              fullWidth
              options={new Array(9).fill(0).map((value, index) => ({ label: `${index}`, value: index }))}
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              variant="outlined"
              label={t('RECREATION.fields.yearlyBudget')}
              margin="dense"
              fullWidth
              value={maxBudget / 100}
              disabled
            />
          </Grid>
        </Grid>
      )}
      <Alert severity="info">{t(employee ? 'RECREATION.maxBudgetHint' : 'RECREATION.maxBudgetHintMulti', { ...tenant?.taxConfig })}</Alert>
    </>
  );
};

export function useRecreationDialogExtensions(): ActivationDialogExtensions<RecreationBenefitOptions> {
  const queryClient = useQueryClient();
  const profileContext = useContext(ProfileEmployeeContext);
  const employee = profileContext?.employee;

  const handleResetBenefitOptions = useCallback(
    (selectedActivation?: BenefitActivationDTO | BenefitTemplateItemDTO): RecreationBenefitOptions => {
      return {
        civilStatus: employee?.civilStatus || EmployeeDTOCivilStatusEnum.Single,
        numberOfKids: employee?.numberOfKids || 0,
      };
    },
    [employee?.civilStatus, employee?.numberOfKids],
  );

  const mapRecreationOptions = useCallback((benefitOptions: RecreationBenefitOptions) => ({}), []);

  const updateEmployeeMutation = useMutation({
    mutationFn: (update: UpdateEmployeeDTO) =>
      apis.employee.updateEmployee({ tenantId: employee!.tenantId, employeeId: employee!.id, updateEmployeeDTO: update }),

    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['tenants', employee!.tenantId, 'employees', employee!.id] }),
  });

  const updateEmployeeIfRequired = useCallback(
    async (benefitOptions: RecreationBenefitOptions) => {
      if (employee && (benefitOptions.civilStatus !== employee.civilStatus || benefitOptions.numberOfKids !== employee.numberOfKids)) {
        await updateEmployeeMutation.mutateAsync({
          civilStatus: benefitOptions.civilStatus,
          numberOfKids: benefitOptions.numberOfKids,
        });
      }
    },
    [employee, updateEmployeeMutation],
  );

  const handleCreateActivation = useCallback<ActivationDialogExtensions<RecreationBenefitOptions>['onCreateActivation']>(
    async (baseValues, benefitOptions, saveActivation) => {
      const create: CreateBenefitActivationDTO = {
        ...baseValues,
        benefit: BenefitDTOBenefitEnum.Recreation,
        recreationOptions: mapRecreationOptions(benefitOptions),
      };
      await updateEmployeeIfRequired(benefitOptions);
      await saveActivation(create);
    },
    [mapRecreationOptions, updateEmployeeIfRequired],
  );

  const handleUpdateActivation = useCallback<NonNullable<ActivationDialogExtensions<RecreationBenefitOptions>['onUpdateActivation']>>(
    async (baseValues, benefitOptions, saveActivation) => {
      const update: UpdateBenefitActivationDTO = {
        ...baseValues,
        recreationOptions: mapRecreationOptions(benefitOptions),
      };
      await updateEmployeeIfRequired(benefitOptions);
      await saveActivation(update);
    },
    [mapRecreationOptions, updateEmployeeIfRequired],
  );

  return useMemo(
    () => ({
      Fields,
      onResetBenefitOptions: handleResetBenefitOptions,
      onCreateActivation: handleCreateActivation,
      onUpdateActivation: handleUpdateActivation,
    }),
    [handleCreateActivation, handleResetBenefitOptions, handleUpdateActivation],
  );
}
