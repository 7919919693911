import { useTranslation } from 'react-i18next';
import { BENEFIT_ICONS } from '../../module/benefits/BenefitIcon';
import BenefitSubPage from '../../module/benefits/BenefitSubPage';
import { AuthorizedLayout } from '../../module/navigation/AuthorizedLayout';
import { useRouterTabsState } from '../../component/tab/tabState';
import { BenefitTabPanel } from '../../component/benefitTabs/BenefitTabPanel';
import { BenefitTab } from '../../component/benefitTabs/BenefitTab';
import { useTenant } from 'probonio-shared-ui/module/me';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { ReceiptDelayBenefitSettingsPanel } from '../../module/benefits/lunch/ReceiptDelayBenefitSettingsPanel';
import { CouponBenefitSettingsPanel } from '../../module/benefits/coupons/CouponBenefitSettingsPanel';

const RECEIPT_UPLOAD_BENEFITS: BenefitDTOBenefitEnum[] = ['LUNCH', 'MOBILITY', 'RECREATION'];

export const FlexSettingsPage: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { tenant } = useTenant();
  const tabsState = useRouterTabsState('/benefits/flex/settings', 'benefit', 'receiptDelay');

  const activeReceiptBenefits = tenant?.flexBenefits?.filter(benefit => RECEIPT_UPLOAD_BENEFITS.includes(benefit)) || [];

  return (
    <AuthorizedLayout title={t('subPage.settings')} breadcrumbs={[{ title: t('FLEX.title'), to: '/benefits/flex' }]}>
      <BenefitSubPage icon={BENEFIT_ICONS.FLEX} title={t('FLEX.title')} subTitle={t('subPage.settings')}>
        <BenefitTabPanel tabsState={tabsState}>
          {activeReceiptBenefits.length > 0 && (
            <BenefitTab value="receiptDelay" label={activeReceiptBenefits.map(benefit => t(`${benefit}.title`)).join(' / ')}>
              <ReceiptDelayBenefitSettingsPanel />
            </BenefitTab>
          )}
          {tenant?.flexBenefits?.includes(BenefitDTOBenefitEnum.Coupons) && (
            <BenefitTab value="coupons" label={t('COUPONS.title')}>
              <CouponBenefitSettingsPanel />
            </BenefitTab>
          )}
        </BenefitTabPanel>
      </BenefitSubPage>
    </AuthorizedLayout>
  );
};
