import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { BikeleasingContractDTO } from 'probonio-shared-ui/api';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  contract: BikeleasingContractDTO;
}

const LabelValueItem: React.FC<{
  label: string;
  value?: string;
  dataTestId?: string;
}> = ({ label, value, dataTestId }) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography variant="body2" color="text.secondary" fontWeight="bold">
        {label}
      </Typography>
      <Typography variant="body2" fontWeight="bold" flexGrow={1} textAlign="right" data-test-id={dataTestId}>
        {value}
      </Typography>
    </Box>
  );
};

export const BikeleasingContractDetails: React.FC<Props> = ({ contract }) => {
  const { t } = useTranslation('benefitsModule');

  const renderEuro = useCallback(
    (value: number | undefined): string => (value !== undefined ? t('common:money', { money: value }) : ''),
    [t],
  );
  const renderDate = useCallback((value: string | undefined): string => (value ? t('common:date', { date: new Date(value) }) : ''), [t]);

  return (
    <Box>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        {t('BIKELEASING.contractIntro')}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={2}>
        {t('BIKELEASING.contractAppInfo')}
      </Typography>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={6} lg={4}>
          <Typography variant="h3" gutterBottom>
            {t('BIKELEASING.section.contract')}
          </Typography>
          <Divider sx={{ marginBottom: 1 }} />
          <Stack direction="column" spacing={0.75}>
            <LabelValueItem
              label={t('BIKELEASING.contract.contractNumber')}
              value={contract.contractNumber}
              dataTestId="bike-field-contractNumber"
            />
          </Stack>
        </Grid>

        <Grid item xs={6} lg={4}>
          <Typography variant="h3" gutterBottom>
            {t('BIKELEASING.section.leasing')}
          </Typography>
          <Divider sx={{ marginBottom: 1 }} />
          <Stack direction="column" spacing={0.75}>
            <LabelValueItem label={t('BIKELEASING.contract.startDate')} value={renderDate(contract.startDate)} />
            <LabelValueItem label={t('BIKELEASING.contract.endDate')} value={renderDate(contract.endDate)} />
            <LabelValueItem label={t('BIKELEASING.contract.leasingRate')} value={renderEuro(contract.leasingRate)} />
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={6} lg={4}>
          <Typography variant="h3" gutterBottom>
            {t('BIKELEASING.section.bike')}
          </Typography>
          <Divider sx={{ marginBottom: 1 }} />
          <Stack direction="column" spacing={0.75}>
            <LabelValueItem label={t('BIKELEASING.contract.transferDate')} value={renderDate(contract.transferDate)} />
            <LabelValueItem label={t('BIKELEASING.contract.brand')} value={contract.bike.brand} />
            <LabelValueItem label={t('BIKELEASING.contract.model')} value={contract.bike.model} />
            <LabelValueItem label={t('BIKELEASING.contract.frameSize')} value={contract.bike.frameSize} />
            <LabelValueItem label={t('BIKELEASING.contract.recommendedRetailPrice')} value={renderEuro(contract.recommendedRetailPrice)} />
            <LabelValueItem label={t('BIKELEASING.contract.purchasePrice')} value={renderEuro(contract.purchasePrice)} />
          </Stack>
        </Grid>

        <Grid item xs={6} lg={4}>
          <Typography variant="h3" gutterBottom>
            {t('BIKELEASING.section.benefits')}
          </Typography>
          <Divider sx={{ marginBottom: 1 }} />
          <Stack direction="column" spacing={0.75}>
            <LabelValueItem label={t('BIKELEASING.contract.employerContribution')} value={renderEuro(contract.employerContribution)} />
            <LabelValueItem label={t('BIKELEASING.contract.insuranceCoverage')} value={contract.insuranceCoverage.join(', ')} />
            <LabelValueItem label={t('BIKELEASING.contract.benefitInKind')} value={renderEuro(contract.benefitInKind)} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
