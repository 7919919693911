import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import { EmployeeDTO } from 'probonio-shared-ui/api';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SearchInput } from '../../component/form/SearchInput';
import { HorizontalBox } from '../../component/layout/BoxLayout';
import { InfiniteScrollReactTable } from '../../component/table/InfiniteScrollReactTable';
import { SortByDef, mapSortModel } from '../../component/table/sortMapper';
import { useLoadMore } from '../../component/table/useLoadMore';
import {
  EmployeeFilter,
  EmployeeFilterState,
  mapEmployeeFilterToRequest,
} from '../../module/benefits/employeeStatusList/employeeFilterState';
import { AnonymousAvatar } from '../../module/userManagement/AnonymousAvatar';
import { UserNameAndAvatar } from '../../module/userManagement/UserNameAndAvatar';
import { useRefetchEmployees } from '../../module/userManagement/query';
import { usePaginatedEmployeeList } from '../../module/userManagement/usePaginatedEmployeeList';
interface Props {
  filterState: EmployeeFilterState;
  filter: EmployeeFilter;
}

enum StatusFilter {
  ALL = 'ALL',
  CONFIRMED = 'CONFIRMED_SEEN',
  SEEN = 'SEEN',
}

export const ReadConfirmationList: React.FC<Props> = ({ filterState, filter }) => {
  const { t } = useTranslation('benefitsModule');
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'name', desc: false }]);
  const { articleId } = useParams();
  const [statusFilter, setStatusFilter] = useState('ALL');

  const COLUMNS: (MRT_ColumnDef<EmployeeDTO> & SortByDef)[] = useMemo(
    () => [
      {
        id: 'name',
        accessorFn: employee => (employee.user ? <UserNameAndAvatar user={employee.user} /> : <AnonymousAvatar />),
        header: t('usersModule:usersList.header.name'),
        sortBy: ['user.lastName', 'user.firstName', 'user.mail'],
      },
      {
        accessorKey: 'employeeNumber',
        header: t('usersModule:usersList.header.employeeNumber'),
      },
      {
        id: 'status',
        accessorFn: employee => (
          <Typography variant="body1">
            {t([`NEWS.readConfirmationTab.${employee.newsArticleStatus}`, 'NEWS.readConfirmationTab.NOT_SEEN'])}{' '}
          </Typography>
        ),
        header: t('usersModule:usersList.header.status'),
        enableSorting: false,
      },
    ],
    [t],
  );

  const confirmedNewsArticleId = statusFilter === StatusFilter.CONFIRMED ? articleId : undefined;
  const unconfirmedNewsArticleId = statusFilter === StatusFilter.SEEN ? articleId : undefined;

  const queryResult = usePaginatedEmployeeList(
    {
      sortBy: mapSortModel(COLUMNS, sorting),
      includeStatusForNewsArticleId: articleId,
      confirmedNewsArticleId,
      unconfirmedNewsArticleId,
      ...mapEmployeeFilterToRequest(filter),
    },
    { enabled: COLUMNS !== undefined },
  );

  const loadMoreProps = useLoadMore(queryResult, 'employees');

  const refreshList = useRefetchEmployees();

  const handleChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      setStatusFilter(e.target.value as StatusFilter);
    },
    [setStatusFilter],
  );

  const handleChangeSearchText = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    event => {
      const setFilter = filterState.setFilter;
      setFilter(prev => ({
        ...prev,
        searchText: event.target.value,
      }));
    },
    [filterState.setFilter],
  );

  return (
    <>
      <HorizontalBox justifyContent="space-between">
        <HorizontalBox gap={1} marginBottom={1.5}>
          <SearchInput
            data-test-id="search-bar"
            value={filterState.filter.searchText || ''}
            onChange={handleChangeSearchText}
            size="small"
            sx={{ width: 275 }}
          />
          <FormControl size="small" sx={{ width: 275 }}>
            <InputLabel id="demo-simple-select-label">{t('NEWS.readConfirmationTab.filterLabel')}</InputLabel>
            <Select
              label={t('NEWS.readConfirmationTab.filterLabel')}
              sx={{ minWidth: 100, height: '100%' }}
              value={statusFilter}
              onChange={handleChange}
            >
              {Object.values(StatusFilter).map(status => (
                <MenuItem data-test-id={`filter-status-selector-value-${status}`} key={status} value={status}>
                  {t(`NEWS.readConfirmationTab.${status}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </HorizontalBox>
        <Box>
          <IconButton onClick={refreshList}>
            <RefreshIcon />
          </IconButton>
        </Box>
      </HorizontalBox>
      <InfiniteScrollReactTable
        {...loadMoreProps}
        columns={COLUMNS}
        enablePagination={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        enableColumnActions={false}
        enableSortingRemoval={false}
        manualSorting
        initialState={{
          expanded: true,
        }}
        onSortingChange={setSorting}
        localization={{ noRecordsToDisplay: t('usersModule:usersList.noResults') }}
        state={{ sorting }}
      />
    </>
  );
};
