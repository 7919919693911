import { InputAdornment } from '@mui/material';
import {
  BenefitActivationDTO,
  BenefitDTOBenefitEnum,
  BenefitTemplateItemDTO,
  CreateBenefitActivationDTO,
  UpdateBenefitActivationDTO,
} from 'probonio-shared-ui/api';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldControl } from '../../../component/form';
import { ActivationDialogExtensions } from '../ActivationDialog';

interface InternetBenefitOptions {
  monthlyBudget: number;
}

const InlineField: React.FC = () => {
  const { t } = useTranslation('benefitsModule');
  const { control } = useFormContext();

  return (
    <TextFieldControl
      InputProps={{
        endAdornment: <InputAdornment position="end">€</InputAdornment>,
      }}
      inputProps={{ step: '5' }}
      rules={{
        required: true,
        min: 5,
        max: 50,
      }}
      type="number"
      control={control}
      variant="outlined"
      name="benefitOptions.monthlyBudget"
      label={t('INTERNET.fields.monthlyBudget')}
      margin="dense"
      fullWidth
    />
  );
};

export function useInternetDialogExtension(): ActivationDialogExtensions<InternetBenefitOptions> {
  const handleResetBenefitOptions = useCallback(
    (selectedActivation?: BenefitActivationDTO | BenefitTemplateItemDTO): InternetBenefitOptions => {
      return {
        monthlyBudget: selectedActivation?.internetOptions ? selectedActivation.internetOptions.monthlyBudget / 100 : 50,
      };
    },
    [],
  );

  const mapInternetOptions = useCallback(
    (benefitOptions: InternetBenefitOptions) => ({
      monthlyBudget: Math.round(benefitOptions.monthlyBudget * 100),
    }),
    [],
  );

  const handleCreateActivation = useCallback<ActivationDialogExtensions<InternetBenefitOptions>['onCreateActivation']>(
    async (baseValues, benefitOptions, saveActivation) => {
      const create: CreateBenefitActivationDTO = {
        ...baseValues,
        benefit: BenefitDTOBenefitEnum.Internet,
        internetOptions: mapInternetOptions(benefitOptions),
      };
      await saveActivation(create);
    },
    [mapInternetOptions],
  );

  const handleUpdateActivation = useCallback<NonNullable<ActivationDialogExtensions<InternetBenefitOptions>['onUpdateActivation']>>(
    async (baseValues, benefitOptions, saveActivation) => {
      const update: UpdateBenefitActivationDTO = {
        ...baseValues,
        internetOptions: mapInternetOptions(benefitOptions),
      };
      await saveActivation(update);
    },
    [mapInternetOptions],
  );

  return useMemo(
    () => ({
      InlineField,
      onResetBenefitOptions: handleResetBenefitOptions,
      onCreateActivation: handleCreateActivation,
      onUpdateActivation: handleUpdateActivation,
    }),
    [handleCreateActivation, handleResetBenefitOptions, handleUpdateActivation],
  );
}
