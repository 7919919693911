import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { Box, IconButton, SvgIconProps, Typography } from '@mui/material';
import { useNavigateBack } from '../../util/useNavigateBack';

interface Props {
  children: React.ReactNode;
  icon?: React.ComponentType<SvgIconProps>;
  title: string;
  subTitle?: string;
  menu?: React.ReactNode;
  navigateBackTo?: string;
  showBack?: boolean;
}

const BenefitSubPage: React.FC<Props> = ({ children, icon, title, subTitle, menu, navigateBackTo = '/', showBack }) => {
  const navigateToHomePage = useNavigateBack(navigateBackTo);

  const IconComponent = icon;
  return (
    <>
      <Box display="flex" flexWrap="wrap" marginTop={1.5} marginBottom={2.5} justifyContent="center" gap={5} alignItems="flex-start">
        <Box flexGrow={1} display="flex" alignItems="center">
          {showBack && (
            <IconButton
              data-test-id="back-button-benefit"
              sx={{
                marginRight: 1,
                padding: 0,
              }}
              onClick={navigateToHomePage}
            >
              <ChevronLeft fontSize="large" data-test-id="back-button-subPage" />
            </IconButton>
          )}
          {IconComponent && <IconComponent color="primary" sx={{ marginRight: 1.5, fontSize: 60 }} />}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography data-test-id="benefit-title" variant="h1">
              {title}
            </Typography>
            <Typography variant="h2" color="text.secondary" data-test-id="benefit-subtitle">
              {subTitle}
            </Typography>
          </Box>
        </Box>
        {menu}
      </Box>
      {children}
    </>
  );
};

export default BenefitSubPage;
