import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { UserDTO } from 'probonio-shared-ui/api';
import { apis } from 'probonio-shared-ui/module/api';
import { setShowWelcomeMessage } from 'probonio-shared-ui/module/me';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogState } from '../../../component/dialog';
import { useAppDispatch } from '../../../redux/hooks';
import { fontSizes } from '../../../theme';
import benefits from './benefits.png';
import employees from './employees.png';
import settings from './settings.png';

interface Props {
  dialogState: DialogState;
  user: UserDTO;
}

const InitialLoginInfoDialog: React.FC<Props> = ({ dialogState, user }) => {
  const dispatch = useAppDispatch();

  const mutation = useMutation({
    mutationFn: async () => {
      await apis.user.updateUser({ userId: user.id, updateUserDTO: { showWelcomeMessage: false } });
    },
  });

  const { t } = useTranslation('firstLoginModule');

  const handleClose = useCallback(() => {
    dispatch(setShowWelcomeMessage(false));
    dialogState.handleClose();
  }, [dialogState, dispatch]);

  const handleCloseDoNotShowAgain = useCallback(() => {
    mutation.mutate();
    handleClose();
  }, [handleClose, mutation]);

  return (
    <Dialog
      open={dialogState.isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      data-test-id="modal-first-time"
    >
      <DialogTitle id="alert-dialog-title" fontSize={fontSizes.lg} textAlign="center">
        {t('title')}
      </DialogTitle>
      <DialogContent sx={{ paddingX: 13 }}>
        <Grid container paddingBottom={2}>
          <Typography paddingBottom={1}>{t('textIntroduction')}</Typography>
          <Typography>{t('textHandbook')}</Typography>
        </Grid>
        <Grid container alignItems="center" paddingBottom={1}>
          <Grid item display="flex" xs={4} justifyContent="center" sx={{ '.test': { border: 'blue' } }}>
            <img src={employees} />
          </Grid>
          <Grid item display="flex" xs={4} justifyContent="center">
            <img src={benefits} />
          </Grid>
          <Grid item display="flex" xs={4} justifyContent="center">
            <img src={settings} />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item display="flex" xs={4} justifyContent="center">
            {t('createEmployees')}
          </Grid>
          <Grid item display="flex" xs={4} justifyContent="center" textAlign="center">
            {t('modifyBenefits')}
          </Grid>
          <Grid item display="flex" xs={4} justifyContent="center">
            {t('listSettings')}
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center">
          <Button variant="outlined" href="https://handbuch.probonio.de" sx={{ margin: 2 }}>
            {t('handBookButton')}
          </Button>
        </Box>
        <Typography textAlign="center">{t('support')}</Typography>
        <Typography paddingTop={2} fontSize={fontSizes.md} fontWeight="bold" textAlign="center">
          {t('team')}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} textAlign="right">
            <Button variant="text" onClick={handleCloseDoNotShowAgain} data-test-id="modal-first-time-no-show">
              {t('doNotShowAgain')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="text" onClick={handleClose} data-test-id="modal-first-time-button-close">
              {t('close')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default InitialLoginInfoDialog;
