import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Divider, Grid, SxProps, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { apis, useTenantQuery } from 'probonio-shared-ui/module/api';
import { useTenant, useTenantID } from 'probonio-shared-ui/module/me';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BenefitTab } from '../../../component/benefitTabs/BenefitTab';
import { BenefitTabPanel } from '../../../component/benefitTabs/BenefitTabPanel';
import { BasicDialog, useNewDialogState } from '../../../component/dialog';
import { DialogLoadingButton } from '../../../component/dialog/DialogLoadingButton';
import { CouponBudgetMonthlyCost } from './CouponBudgetPanelTabs/CouponBudgetMonthlyCost';
import { CouponBudgetTotalCredit } from './CouponBudgetPanelTabs/CouponBudgetTotalCredit';
import { CouponsIncreaseBudgetDialog } from './CouponsIncreaseBudgetDialog';
import { useRefetchBudgetOrders } from './query';

export const CouponBudgetPanel: React.FC = () => {
  const { t } = useTranslation('couponsModule');
  const getTenantId = useTenantID();
  const { tenant, invalidateTenant } = useTenant();
  const refetchBudgetOrders = useRefetchBudgetOrders();
  const [amount, setAmount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const { data: expenses } = useTenantQuery(['tenants', getTenantId(), 'benefits', 'expenses', apis.benefits.getExpenses.name], tenantId =>
    apis.benefits.getExpenses({ tenantId }).then(resp => resp.data),
  );
  const increaseBudgetDialogState = useNewDialogState();
  const successDialogState = useNewDialogState();

  const budgetOrderMutation = useMutation({
    mutationFn: async (increaseAmount: number) => {
      setAmount(increaseAmount);
      await apis.coupons.createBudgetOrder({
        tenantId: getTenantId(),
        // input amount is in euro and server requires cent
        createCouponBudgetOrderDTO: { amount: Math.round(increaseAmount) },
      });
    },

    onSuccess: async () => {
      await refetchBudgetOrders();
      increaseBudgetDialogState.dialogState.handleClose();
      successDialogState.handleOpen();
    },
  });

  const autoBudgetMutation = useMutation({
    mutationFn: async (enabled: boolean) => {
      await apis.tenants.updateTenant({
        tenantId: getTenantId(),
        updateTenantDTO: {
          enableAutomatedBudgetOrders: enabled,
        },
      });
      return enabled;
    },

    onSuccess: async enabled => {
      increaseBudgetDialogState.dialogState.handleClose();
      invalidateTenant();
      if (enabled) {
        enqueueSnackbar(t('budgetPanelIncreaseBudgetDialog.tabs.automatic.enabledSuccessMessage'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('budgetPanelIncreaseBudgetDialog.tabs.automatic.disabledSuccessMessage'), { variant: 'success' });
      }
    },
  });

  const benefitTabStyle: SxProps = { px: 2.5, py: 1, minWidth: '550px', minHeight: '80px', display: 'flex' };
  const isSepaActive = tenant?.sepa && tenant?.sepaMandate;

  return (
    <>
      <BenefitTabPanel size="sm" variant="fullWidth">
        <BenefitTab labelIcon={<AssessmentIcon />} label={t('budgetPanel.tabs.expenses')} sx={benefitTabStyle}>
          <CouponBudgetMonthlyCost tenant={tenant} expenses={expenses} />
        </BenefitTab>
        <BenefitTab labelIcon={<AccountBalanceWalletIcon />} label={t('budgetPanel.tabs.balance')} sx={benefitTabStyle}>
          <CouponBudgetTotalCredit tenant={tenant} increaseBudgetDialogState={increaseBudgetDialogState} />
        </BenefitTab>
      </BenefitTabPanel>
      <CouponsIncreaseBudgetDialog
        dialogState={increaseBudgetDialogState.dialogState}
        onIncreaseBudget={budgetOrderMutation.mutate}
        onSetAutoBudget={autoBudgetMutation.mutate}
        isLoading={budgetOrderMutation.isPending}
        expenses={expenses}
      />
      <BasicDialog
        dialogState={successDialogState.dialogState}
        title={t('budgetPanelIncreaseBudgetDialog.successDialog.title')}
        actions={
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <DialogLoadingButton onClick={successDialogState.dialogState.handleClose} data-test-id="budget-success-dialog-close-button">
              {t('budgetPanelIncreaseBudgetDialog.successDialog.actions.confirm')}
            </DialogLoadingButton>
          </Box>
        }
        content={
          <Box textAlign="center">
            {/* Order success message with icon */}
            <Box>
              <CheckCircleIcon color="success" fontSize="large" />
            </Box>
            <Box>
              <Typography fontWeight={700}>{t('budgetPanelIncreaseBudgetDialog.successDialog.orderCompleted')}</Typography>
              <Typography>{t('budgetPanelIncreaseBudgetDialog.successDialog.orderCompletedInfo', { tenantMail: tenant?.mail })}</Typography>
              {isSepaActive && (
                <Typography style={{ whiteSpace: 'pre-line' }}>
                  {t('budgetPanelIncreaseBudgetDialog.successDialog.orderCompletedInfoSepa', { tenantIban: tenant?.iban, money: amount })}
                </Typography>
              )}
            </Box>

            {!isSepaActive && (
              <>
                <Divider sx={{ paddingTop: 1 }} />
                <Box sx={{ paddingY: 1 }}>
                  <Typography fontWeight={700}>{t('budgetPanelIncreaseBudgetDialog.successDialog.transferData.title')}</Typography>
                  <Grid container spacing={2}>
                    {/* Header of transfer data */}
                    <Grid item xs={6} sx={{ textAlign: 'end' }}>
                      <Typography>{t('budgetPanelIncreaseBudgetDialog.successDialog.transferData.recipient')}</Typography>
                      <Typography>{t('budgetPanelIncreaseBudgetDialog.successDialog.transferData.iban')}</Typography>
                      <Typography>{t('budgetPanelIncreaseBudgetDialog.successDialog.transferData.bic')}</Typography>
                      <Typography>{t('budgetPanelIncreaseBudgetDialog.successDialog.transferData.usage')}</Typography>
                      <Typography>{t('budgetPanelIncreaseBudgetDialog.successDialog.transferData.amount')}</Typography>
                    </Grid>
                    {/* Information for transfer data */}
                    <Grid item xs={6} sx={{ textAlign: 'start' }}>
                      <Typography>{t('budgetPanelIncreaseBudgetDialog.successDialog.transferData.probonioData.recipient')}</Typography>
                      <Typography>{t('budgetPanelIncreaseBudgetDialog.successDialog.transferData.probonioData.iban')}</Typography>
                      <Typography>{t('budgetPanelIncreaseBudgetDialog.successDialog.transferData.probonioData.bic')}</Typography>
                      <Typography>{tenant?.name}</Typography>
                      <Typography>{t('common:money', { money: amount })}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </Box>
        }
      />
    </>
  );
};
