import React from 'react';
import { TextFieldControl } from '../form';
import { Control, FieldPath, FieldValues } from 'react-hook-form';

interface Props<T extends FieldValues, TName extends FieldPath<T>> {
  name: TName;
  control: Control<T>;
}

const ColorPicker = <T extends FieldValues, TName extends FieldPath<T>>({ name, control }: Props<T, TName>): JSX.Element => {
  return (
    <>
      <TextFieldControl
        control={control}
        name={name}
        type="color"
        sx={{
          width: '100px',
          input: {
            padding: 'unset',
            height: ' 100px',
          },
          fieldset: {
            display: 'none',
          },
        }}
      />
    </>
  );
};

export { ColorPicker };
