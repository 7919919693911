import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import RedeemIcon from '@mui/icons-material/Redeem';
import SellIcon from '@mui/icons-material/Sell';
import WifiIcon from '@mui/icons-material/Wifi';
import { Card, Grid, InputAdornment, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { PalmTreeIcon } from 'probonio-shared-ui/component/icon';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactChart } from '../../component/chart';
import { TextFieldControl } from '../../component/form';
import { BenefitSlider } from './CalculatorBenefitSlider';
import { CalculatorInputField } from './CalculatorInputField';
import { useChartDataCalculator } from './calculatorChartData';
import { useCalculator, useGrossCalculator } from './calculatorQueries';
import { CALCULATOR_DEFAULT_VALUES, CalculatorFormValues, useGrossNetCalculationState } from './useGrossNetCalculationState';

export const GrossNetCalculator: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation('equivalenceCalculator');
  const tenantCostFactor = 1.2;

  const { calculatorFormValues, calculationApiData, setCalculatorFormValues } = useGrossNetCalculationState();

  const { control, handleSubmit, watch, setValue, formState, reset } = useForm<CalculatorFormValues>({
    defaultValues: CALCULATOR_DEFAULT_VALUES,
  });

  const { data: resData, isLoading: isLoadingCalculator, error } = useCalculator(calculationApiData);
  const { data: resDataWithoutBenefits, isLoading: isLoadingWithoutBenefits } = useCalculator({
    ...calculationApiData,
    lunchBenefitAmount: 0,
    voucherBenefitAmount: 0,
    mobilityBenefitAmount: 0,
    giftBenefitAmount: 0,
    internetBenefitAmount: 0,
    recreationBenefitAmount: 0,
  });
  const { data: grossResult, isLoading: isLoadingGrossCalculator } = useGrossCalculator(
    {
      netIncome: resData?.netYearlyIncome,
      childAmount: calculationApiData.childAmount!,
      isChurch: calculationApiData.isChurch!,
      isPrivatelyInsured: calculationApiData.isPrivatelyInsured!,
      privateInsuranceAmount: calculationApiData.privateInsuranceAmount!,
      taxClass: calculationApiData.taxClass!,
    },
    { enabled: !!resData },
  );

  const isLoading = isLoadingCalculator || isLoadingWithoutBenefits || isLoadingGrossCalculator;

  const { tenant } = useTenant();

  const handleChangeFieldValue = useCallback(
    (fieldName: keyof CalculatorFormValues) => (value: number) => {
      const newValue: number | boolean = value;
      setValue(fieldName, newValue, { shouldDirty: true });
    },
    [setValue],
  );

  const handleCalc = useCallback(
    (values: CalculatorFormValues) => {
      setCalculatorFormValues({ ...values, resultTenantValue: values.grossIncome * tenantCostFactor });
      setValue('resultTenantValue', values.grossIncome * tenantCostFactor);
      reset({ ...values, resultTenantValue: values.grossIncome * tenantCostFactor });
    },
    [reset, setCalculatorFormValues, setValue],
  );

  useEffect(() => {
    if (!isLoading) {
      setValue('resultNetValue', resDataWithoutBenefits?.netYearlyIncome || 0);
    }
  }, [isLoading, resDataWithoutBenefits?.netYearlyIncome, setValue]);

  const netValueWithBenefits = resData?.netYearlyIncome || 0;
  const calculatedEmployeeNetAdvantage = (resData?.netYearlyIncome || 0) - (resDataWithoutBenefits?.netYearlyIncome || 0);

  const taxClasses = new Array(6).fill(0).map((value, index) => ({ value: index + 1, label: index + 1 }));

  const childAmounts = new Array(9).fill(0).map((value, index) => ({ value: index, label: index }));

  const insuranceOptions = [
    {
      value: 0,
      label: t('personalConfiguration.insuranceType.lawful'),
    },
    {
      value: 1,
      label: t('personalConfiguration.insuranceType.private'),
    },
  ];

  const chartData = useChartDataCalculator(resData);

  const showPrivateMonthly = watch('isPrivatelyInsured') > 0;

  return (
    <form onSubmit={handleSubmit(handleCalc)}>
      <Grid container columnSpacing={2} mt={2}>
        <Grid item xs={8}>
          <Card
            sx={{
              display: 'flex',
              padding: 1.5,
              flexDirection: 'column',
              justifyContent: 'space-between',
              mb: 2,
            }}
          >
            <Box display="flex" alignItems="center" gap={2} justifyContent="space-between" mb={1}>
              <Typography variant="h2">{t('inputFields.title')}</Typography>
              <LoadingButton type="submit" size="small" disabled={!formState.isDirty} loading={isLoading}>
                {t('inputFields.calculateButton')}
              </LoadingButton>
            </Box>
            <Grid container rowSpacing={2} alignItems="center">
              <Grid item xs={3}>
                <Typography variant="h3"> {t('inputFields.fixed')}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h3"> {t('inputFields.standard')}</Typography>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={3}>
                <Typography variant="h3"> {t('inputFields.withCompany')}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography id="demo-radio-buttons-group-label">{t('inputFields.grossPerYear')}</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFieldControl
                  control={control}
                  type="number"
                  name="grossIncome"
                  InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                />
              </Grid>
              <Grid item xs={1} textAlign="center">
                <Typography id="demo-radio-buttons-group-label">=</Typography>
              </Grid>
              <Grid item xs={1} display="flex" gap={2}>
                <Typography>{`${Math.round(calculatorFormValues.grossIncome).toLocaleString('de')}€`}</Typography>
              </Grid>
              <Grid item xs={4} display="flex" gap={2}>
                <ArrowRightAltIcon color="success" />
                <Typography color={theme.palette.success.main}>
                  {t('inputFields.grossCalculated', {
                    value: Math.round(grossResult?.grossYearlyIncome || 0).toLocaleString('de'),
                  })}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography id="demo-radio-buttons-group-label">{t('inputFields.netPerYear')}</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFieldControl
                  disabled
                  control={control}
                  type="number"
                  name="resultNetValue"
                  InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography id="demo-radio-buttons-group-label" textAlign="center">
                  {resData?.benefitCalculatedResult ? `<` : `=`}
                </Typography>
              </Grid>
              <Grid item xs={1} display="flex" gap={2}>
                <Typography>{`${Math.round(netValueWithBenefits).toLocaleString('de')}€`}</Typography>
              </Grid>
              <Grid item xs={4} display="flex" gap={2}>
                <ArrowRightAltIcon color="success" />
                <Typography color={theme.palette.success.main}>
                  {calculatedEmployeeNetAdvantage
                    ? t('inputFields.netCalculatedMore', { value: Math.round(calculatedEmployeeNetAdvantage).toLocaleString('de') })
                    : t('inputFields.netCalculatedSame')}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography id="demo-radio-buttons-group-label">{t('inputFields.tenantCostPerYear')}</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextFieldControl
                  disabled
                  control={control}
                  type="number"
                  name="resultTenantValue"
                  InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography id="demo-radio-buttons-group-label" textAlign="center">{`>`}</Typography>
              </Grid>
              <Grid item xs={1} display="flex" gap={2}>
                <Typography fontWeight="bold">{`${Math.round(resData?.calculatedTenantCostsWithBenefits || 0).toLocaleString(
                  'de',
                )}€`}</Typography>
              </Grid>
              <Grid item xs={4} display="flex" gap={2}>
                <ArrowRightAltIcon color="success" />
                <Typography fontWeight="bold" color={theme.palette.success.main}>
                  {Math.round(resData?.calculatedTenantCostsWithBenefits || 0) < calculatorFormValues.resultTenantValue
                    ? t('inputFields.tenantCalculatedMore', {
                        value: Math.round(resData?.calculatedDeductedTenantCostsWithBenefits || 0).toLocaleString('de'),
                      })
                    : t('inputFields.tenantCalculatedSame')}
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Card
            sx={{
              display: 'flex',
              padding: 1.5,
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h2">{t('graph.title')}</Typography>
            {!error && (
              <ReactChart config={chartData.config} sx={{ height: '100%', width: '100%' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    left: chartData.chartArea?.left,
                    top: chartData.chartArea?.top,
                    width: chartData.chartArea && chartData.chartArea.right - chartData.chartArea.left,
                    height: chartData.chartArea && chartData.chartArea.bottom - chartData.chartArea.top,
                    paddingBottom: theme => theme.spacing(4),
                  }}
                />
              </ReactChart>
            )}
          </Card>
        </Grid>
        <Grid data-test-id="user-profile-card-benefit-content" item marginBottom={2} xs={4}>
          <Card sx={{ padding: 1.5 }}>
            <Grid display="flex" flexDirection="column" flexGrow={1} px={2} gap={0.5} pb={2}>
              <Typography gutterBottom variant="h2">
                {t('personalConfiguration.title')}
              </Typography>
              <CalculatorInputField
                control={control}
                labelText={t('personalConfiguration.taxClass')}
                name="taxClass"
                selectorOptions={taxClasses}
                dataTestId="taxClass"
              />
              <CalculatorInputField
                control={control}
                labelText={t('personalConfiguration.insuranceType.title')}
                name="isPrivatelyInsured"
                selectorOptions={insuranceOptions}
                dataTestId="isPrivatelyInsured"
              />
              {showPrivateMonthly && (
                <Grid display="flex" justifyContent="space-between" alignItems="center" gap={2}>
                  <Typography>{t('personalConfiguration.privateInsuranceAmount')}</Typography>
                  <TextFieldControl
                    sx={{ width: 90 }}
                    control={control}
                    name="privateInsuranceAmount"
                    InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
                  />
                </Grid>
              )}
              <CalculatorInputField
                control={control}
                labelText={t('personalConfiguration.children')}
                name="childAmount"
                selectorOptions={childAmounts}
                dataTestId="childAmount"
              />
              <CalculatorInputField control={control} labelText={t('personalConfiguration.church')} name="isChurch" isBoolean />
            </Grid>
            <Grid px={2} display="flex" flexDirection="column" gap={2}>
              <Typography gutterBottom variant="h2">
                {t('benefitConfiguration.title')}
              </Typography>
              <BenefitSlider
                icon={<FastfoodIcon />}
                label={t('benefitConfiguration.lunchPerMonth')}
                value={watch('lunchBenefitAmount')}
                onChange={handleChangeFieldValue('lunchBenefitAmount')}
                maxStep={15}
                perStep={1}
                dataTestId="lunchSlider"
              />
              <BenefitSlider
                icon={<SellIcon />}
                label={t('benefitConfiguration.voucherPerMonth')}
                value={watch('voucherBenefitAmount')}
                onChange={handleChangeFieldValue('voucherBenefitAmount')}
                maxStep={50}
                perStep={5}
                isPrice
                dataTestId="voucherSlider"
              />
              <BenefitSlider
                icon={<RedeemIcon />}
                label={t('benefitConfiguration.giftsPerYear')}
                value={watch('giftBenefitAmount')}
                onChange={handleChangeFieldValue('giftBenefitAmount')}
                maxStep={3}
                perStep={1}
                dataTestId="giftSlider"
              />
              <BenefitSlider
                icon={<DirectionsBusIcon />}
                label={t('benefitConfiguration.mobilityPerMonth')}
                value={watch('mobilityBenefitAmount')}
                onChange={handleChangeFieldValue('mobilityBenefitAmount')}
                maxStep={200}
                perStep={10}
                isPrice
                dataTestId="mobilitySlider"
              />
              <BenefitSlider
                icon={<WifiIcon />}
                label={t('benefitConfiguration.internetPerMonth')}
                value={watch('internetBenefitAmount')}
                onChange={handleChangeFieldValue('internetBenefitAmount')}
                maxStep={50}
                perStep={5}
                isPrice
                dataTestId="internetSlider"
              />
              <BenefitSlider
                icon={<PalmTreeIcon />}
                label={t('benefitConfiguration.recreationPerMonth')}
                value={watch('recreationBenefitAmount')}
                onChange={handleChangeFieldValue('recreationBenefitAmount')}
                maxStep={tenant?.taxConfig?.recreationChildAmount ? (tenant.taxConfig.recreationChildAmount * 10) / 100 : 52}
                perStep={tenant?.taxConfig?.recreationChildAmount ? tenant?.taxConfig?.recreationChildAmount / 100 : 52}
                isPrice
                dataTestId="recreationSlider"
              />
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};
