import GradingIcon from '@mui/icons-material/Grading';
import { Box, Tooltip, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from '@tanstack/react-query';
import { ReportFilterState } from '.';
import { useNewDialogState } from '../../../component/dialog';
import ReportDownloadDialog from '../ReportDownloadDialog/ReportDownloadDialog';
import { SearchInput } from '../../../component/form/SearchInput';
import { HorizontalBox } from '../../../component/layout/BoxLayout';
import useDebounce from 'probonio-shared-ui/utils/useDebounce';
import { LoadingButton } from 'probonio-shared-ui/component/button';
import { CustomDatePicker } from '../../../component/datePicker';
import { EmployeeClearFilterButton } from '../employeeStatusList/EmployeeClearFilterButton';
import { DepartmentSelector } from '../../selectors/departments/DepartmentSelector';

interface Props {
  filterState: ReportFilterState;
  exportMutation: UseMutationResult<void, Error, DateTime | undefined>;
}

export const ReportFilterSelector: React.FC<Props> = ({ filterState, exportMutation }) => {
  const { t } = useTranslation('benefitsModule');
  const theme = useTheme();
  const [searchText, setSearchText] = useState('');
  const dialogState = useNewDialogState();
  const searchTextDebounced = useDebounce(searchText);

  const handleChangeSearchText = useCallback<React.ChangeEventHandler<HTMLInputElement>>(event => {
    setSearchText(event.target.value);
  }, []);
  useEffect(() => {
    const setFilter = filterState.setFilter;
    setFilter(prev => ({
      ...prev,
      searchText: searchTextDebounced,
    }));
  }, [filterState.setFilter, searchTextDebounced]);

  const handleDepartmentChange = useCallback(
    (department?: string) => {
      const setFilter = filterState.setFilter;
      setFilter(prev => ({
        ...prev,
        department,
      }));
    },
    [filterState.setFilter],
  );

  const handleMonthChange = useCallback(
    (date: Date | null, keyboardInputValue?: string | undefined) => {
      if (!date) {
        return;
      }

      const setFilter = filterState.setFilter;
      setFilter(prev => ({
        ...prev,
        month: DateTime.fromJSDate(date),
      }));
    },
    [filterState.setFilter],
  );

  const handleDetailReportExport = useCallback(
    (month?: DateTime) => {
      exportMutation.mutate(month || filterState.filter.month, { onSuccess: () => dialogState.dialogState.handleClose() });
    },
    [dialogState.dialogState, exportMutation, filterState.filter.month],
  );

  const handleRevertFilters = useCallback(() => {
    const setFilter = filterState.setFilter;
    setFilter(prev => ({ ...prev, department: undefined }));
    setSearchText('');
  }, [filterState.setFilter]);

  return (
    <>
      <HorizontalBox gap={1}>
        <HorizontalBox flexGrow={1} gap={1}>
          <SearchInput size="small" value={searchText} onChange={handleChangeSearchText} />
          <DepartmentSelector
            size="small"
            value={filterState.filter.department}
            onChange={handleDepartmentChange}
            sx={{ width: 275, background: theme.palette.background.paper }}
          />
          {(searchText || filterState.filter.department) && <EmployeeClearFilterButton onRevertFilters={handleRevertFilters} />}
        </HorizontalBox>
        <Box display="flex" rowGap={1} columnGap={2}>
          <CustomDatePicker
            minDate={DateTime.now().minus({ year: 5 }).toJSDate()}
            maxDate={new Date()}
            onChange={handleMonthChange}
            value={filterState.filter.month.toJSDate()}
            monthYearPicker
            showNextPreviousToggle
            fullWidth
            InputProps={{
              size: 'small',
              sx: { flexShrink: 1 },
            }}
          />

          <Tooltip title={t('report.receiptExportTooltip') || ''}>
            <LoadingButton onClick={dialogState.handleOpen} loading={exportMutation.isPending} size="small" variant="topBarButtonBorder">
              <GradingIcon />
            </LoadingButton>
          </Tooltip>
        </Box>
      </HorizontalBox>
      <ReportDownloadDialog
        message={t('report.reportDialogMessage')}
        loading={exportMutation.isPending}
        dialogState={dialogState.dialogState}
        handleConfirm={handleDetailReportExport}
        initialMonth={filterState.filter.month}
      />
    </>
  );
};
