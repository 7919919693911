import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps, SelectVariants } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export enum SelectorTypes {
  COST_CENTER = 'costCenter',
  DEPARTMENT = 'department',
}

interface Props extends Omit<SelectProps<string>, 'onChange' | 'variant'> {
  selectorType: SelectorTypes;
  selectorData?: string[];
  onChange: (selector?: string) => void;
  variant?: SelectVariants;
}

export const SelectorLayout = ({ selectorType, selectorData, onChange, sx, value, ...props }: Props) => {
  const { t } = useTranslation('filterModule');
  const handleChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      onChange(e.target.value || undefined);
    },
    [onChange],
  );

  return (
    <FormControl size={props.size}>
      <InputLabel id="demo-simple-select-label">{t(selectorType)}</InputLabel>
      <Select
        data-test-id={`${selectorType}-selector`}
        label={t(selectorType)}
        labelId="demo-simple-select-label"
        sx={{ minWidth: 100, height: '100%', ...sx }}
        value={value || ''}
        {...props}
        onChange={handleChange}
      >
        <MenuItem value="">
          <em data-test-id={`${selectorType}-selector-all`}>{t('all')}</em>
        </MenuItem>
        {(selectorData || []).map(singleItem => (
          <MenuItem data-test-id={`filter-${selectorType}-selector-value-${singleItem}`} key={singleItem} value={singleItem}>
            {singleItem}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
