import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TutorialStepDefinition } from '../TutorialStep';
import { useManualStep } from '../useManualStep';

const STEP_ID = 'employmentContract';

export function useEmploymentContractStep(): TutorialStepDefinition {
  const { t } = useTranslation('tutorialModule');
  const manualStepProps = useManualStep(STEP_ID);

  return useMemo(
    () => ({
      ...t('steps.employmentContract', { returnObjects: true }),
      ...manualStepProps,
    }),
    [manualStepProps, t],
  );
}
