import { OnChangeFn, SortingState } from '@tanstack/react-table';
import { MRT_Row } from 'material-react-table';
import { EmployeeDTO, EmployeeDTOStatusEnum } from 'probonio-shared-ui/api';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteScrollReactTable } from '../../component/table/InfiniteScrollReactTable';
import { mapSortModel } from '../../component/table/sortMapper';
import { useLoadMore } from '../../component/table/useLoadMore';
import { useNavigateWithPrevPath } from '../../util/useNavigateWithPrevPath';
import { EmployeeFilter, mapEmployeeFilterToRequest } from '../benefits/employeeStatusList/employeeFilterState';
import { useColumns } from './Column';
import { usePaginatedEmployeeList } from './usePaginatedEmployeeList';
import { RowSelectionState, useManualRowSelection } from '../../component/table/useManualRowSelection';

interface Props {
  filter?: EmployeeFilter;
  sorting: SortingState;
  rowSelectionState: RowSelectionState;
  enableRowSelection?: boolean;
  onChangeSort: (model: SortingState) => void;
}

const UsersListComp: React.FC<Props> = ({ filter, sorting, rowSelectionState, enableRowSelection, onChangeSort }) => {
  const { t } = useTranslation('usersModule');
  const navigate = useNavigateWithPrevPath();
  const columns = useColumns();

  const queryResult = usePaginatedEmployeeList(
    {
      sortBy: mapSortModel(columns, sorting),
      includeBenefits: true,
      includeUpcoming: true,
      ...mapEmployeeFilterToRequest(filter),
    },
    { enabled: columns !== undefined },
  );

  const loadMoreProps = useLoadMore(queryResult, 'employees');

  const isRowSelectable = useCallback((employee: EmployeeDTO) => employee.status !== EmployeeDTOStatusEnum.Inactive, []);
  const handleRowId = useCallback((employee: EmployeeDTO) => employee.id, []);
  const manualRowSelectionProps = useManualRowSelection(rowSelectionState, loadMoreProps.data, handleRowId, isRowSelectable);

  const handleChangeSort: OnChangeFn<SortingState> = useCallback(
    updaterOrValue => {
      if (typeof updaterOrValue === 'function') {
        onChangeSort(updaterOrValue(sorting));
      } else {
        onChangeSort(updaterOrValue);
      }
    },
    [onChangeSort, sorting],
  );

  const handleRowProps = useCallback(
    ({ row }: { row: MRT_Row<EmployeeDTO> }) => {
      function handleRowClick(event: unknown): void {
        navigate(`/employees/${row.original.id}`);
      }

      return {
        onClick: handleRowClick,
        sx: { cursor: 'pointer' },
      };
    },
    [navigate],
  );

  return (
    <InfiniteScrollReactTable
      key={enableRowSelection ? 'edit-mode-list' : 'view-mode-list'}
      {...loadMoreProps}
      {...(enableRowSelection ? manualRowSelectionProps : { muiTableBodyRowProps: handleRowProps })}
      columns={columns}
      enablePagination={false}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enableSortingRemoval={false}
      manualSorting
      onSortingChange={handleChangeSort}
      state={useMemo(() => ({ sorting, ...manualRowSelectionProps.state }), [manualRowSelectionProps.state, sorting])}
      localization={{ noRecordsToDisplay: t('usersList.noResults') }}
      defaultColumn={{ minSize: 40, maxSize: 1000, size: 150 }}
    />
  );
};

export const UsersList = React.memo(UsersListComp);
