import React, { useCallback } from 'react';
import { SearchInput } from '../../../component/form/SearchInput';
import { HorizontalBox } from '../../../component/layout/BoxLayout';
import { EmployeeFilter, EmployeeFilterState } from './employeeFilterState';
import { BenefitFilter, BenefitSelector } from '../../userManagement/BenefitSelector';
import { EmployeeClearFilterButton } from './EmployeeClearFilterButton';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CostCenterSelector } from '../../selectors/costCenters/CostCenterSelector';
import { DepartmentSelector } from '../../selectors/departments/DepartmentSelector';

interface Props {
  filterState: EmployeeFilterState;
  showBenefitFilter?: boolean;
  showStatusFilter?: boolean;
  showDepartmentFilter?: boolean;
  showCostCenterFilter?: boolean;
}

export const EmployeeFilterSelector: React.FC<Props> = ({
  filterState,
  showBenefitFilter,
  showStatusFilter,
  showDepartmentFilter = true,
  showCostCenterFilter = true,
}) => {
  const { t } = useTranslation('usersModule');

  const handleFilterChange = useCallback(
    (key: keyof EmployeeFilter, value?: string) => {
      const setFilter = filterState.setFilter;
      setFilter(prev => ({
        ...prev,
        [key]: value,
      }));
    },
    [filterState.setFilter],
  );

  const handleChangeSearchText = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    event => {
      handleFilterChange('searchText', event.target.value);
    },
    [handleFilterChange],
  );

  const handleDepartmentChange = useCallback(
    (department?: string) => {
      handleFilterChange('department', department);
    },
    [handleFilterChange],
  );

  const handleCostCenterChange = useCallback(
    (costCenter?: string) => {
      handleFilterChange('costCenter', costCenter);
    },
    [handleFilterChange],
  );

  const handleSetBenefitFilter = useCallback(
    (benefitFilter: BenefitFilter) => {
      filterState.setFilter(filter => ({ ...filter, benefitFilter }));
    },
    [filterState],
  );

  const handleChangeStatus = useCallback(
    (event: SelectChangeEvent<string>) => {
      filterState.setFilter(filter => ({
        ...filter,
        showActiveUser: (event.target.value === 'ALL' ? undefined : event.target.value) as EmployeeFilter['showActiveUser'],
      }));
    },
    [filterState],
  );

  const handleRevertFilters = useCallback(() => {
    const setFilter = filterState.setFilter;
    setFilter(filter => {
      const clearedFilter = { ...filter, department: undefined, costCenter: undefined, searchText: undefined };
      if (showBenefitFilter) {
        clearedFilter.benefitFilter = undefined;
      }
      if (showStatusFilter) {
        clearedFilter.showActiveUser = undefined;
      }
      return clearedFilter;
    });
  }, [filterState.setFilter, showBenefitFilter, showStatusFilter]);
  const isFilterSet =
    (showBenefitFilter && filterState.filter.benefitFilter) ||
    (showDepartmentFilter && filterState.filter.department) ||
    (showCostCenterFilter && filterState.filter.costCenter) ||
    filterState.filter.searchText ||
    (showStatusFilter && filterState.filter.showActiveUser);

  return (
    <HorizontalBox gap={1}>
      <SearchInput
        data-test-id="search-bar"
        value={filterState.filter.searchText || ''}
        onChange={handleChangeSearchText}
        size="small"
        sx={{ width: 250 }}
      />

      {showDepartmentFilter && (
        <DepartmentSelector value={filterState.filter.department} onChange={handleDepartmentChange} size="small" sx={{ width: 250 }} />
      )}
      {showCostCenterFilter && (
        <CostCenterSelector value={filterState.filter.costCenter} onChange={handleCostCenterChange} size="small" sx={{ width: 250 }} />
      )}
      {showStatusFilter && (
        <FormControl size="small">
          <InputLabel>{t('usersList.statusFilterLabel')}</InputLabel>
          <Select
            data-test-id="status-selector"
            label={t('usersList.statusFilterLabel')}
            onChange={handleChangeStatus}
            value={filterState.filter.showActiveUser || 'ALL'}
            sx={{ width: 250, height: '100%', backgroundColor: 'background.paper' }}
          >
            <MenuItem value="ALL">
              <em>{t('departmentModule:all')}</em>
            </MenuItem>
            <MenuItem value="ACTIVE">{t('usersPage:tab.active')}</MenuItem>
            <MenuItem value="REGISTRATION">{t('usersPage:tab.registration')}</MenuItem>
          </Select>
        </FormControl>
      )}
      {showBenefitFilter && (
        <BenefitSelector benefitFilter={filterState.filter.benefitFilter} onChangeBenefitFilter={handleSetBenefitFilter} />
      )}
      {isFilterSet && <EmployeeClearFilterButton onRevertFilters={handleRevertFilters} />}
    </HorizontalBox>
  );
};
