import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Alert, Box, Button, Chip, Divider, Grid, InputAdornment, Paper, Stack, ToggleButton, Typography, useTheme } from '@mui/material';
import type { Variant } from '@mui/material/styles/createTypography';
import { RadioControl, SwitchControl } from 'probonio-shared-ui/component/form';
import { useForm } from 'react-hook-form';
import { DatePickerControl, SelectControl, TextFieldControl } from '../../component/form';
import { CheckboxControl } from '../../component/form/CheckboxControl';
import { SearchInput } from '../../component/form/SearchInput';
import { SelectImageControl } from '../../component/form/SelectImageControl';
import TextFieldChipsControl from '../../component/form/TextFieldChipsControl';
import ToggleButtonGroupControl from '../../component/form/ToggleButtonGroupControl';
import { UploadControl } from '../../component/form/UploadControl';
import React from 'react';

const HtmlEditor = React.lazy(() => import('../../component/form/RichTextEditor/HtmlEditor'));

const Colors: React.FC = () => {
  const themeColors = [
    'primary.main',
    'primaryMuted.main',
    'secondary.main',
    'info.main',
    'success.main',
    'warning.main',
    'error.main',
    'background.default',
    'background.paper',
  ];

  return (
    <>
      {themeColors.map(color => (
        <Box
          key={color}
          width={200}
          height={60}
          margin={1}
          padding={1}
          sx={{
            backgroundColor: `${color}`,
            color: color.endsWith('.main') ? color.replace('.main', '.contrastText') : undefined,
            borderRadius: 1,
            border: '1px solid black',
          }}
        >
          {color}
        </Box>
      ))}
    </>
  );
};

const Fonts: React.FC = () => {
  const theme = useTheme();

  const variants = Object.keys(theme.typography)
    .filter(
      key =>
        ![
          'htmlFontSize',
          'pxToRem',
          'fontFamily',
          'fontSize',
          'fontWeightLight',
          'fontWeightRegular',
          'fontWeightMedium',
          'fontWeightBold',
        ].includes(key),
    )
    .sort() as Variant[];
  const textColors = ['text.primary', 'text.secondary', 'primary', 'error', 'success.main', 'warning.main'] as const;

  return (
    <>
      {variants.map(variant => (
        <div key={variant}>
          <Typography variant={variant} gutterBottom>
            {variant.startsWith('h') ? `Heading level ${variant}` : `Typography variant ${variant}`} {theme.typography[variant]!.fontSize}
          </Typography>
        </div>
      ))}
      <Divider sx={{ marginY: 2 }} />
      {textColors.map(color => (
        <div key={color}>
          <Typography variant="h2" color={color} gutterBottom>
            Heading Level 2; color: {color}
          </Typography>
          <Typography variant="body1" color={color} gutterBottom>
            Typography Text; variant: body1 color: {color}
          </Typography>
        </div>
      ))}
      <div>
        <a href="https://www.probonio.de">Link</a>
      </div>
    </>
  );
};

const Buttons: React.FC = () => {
  return (
    <Stack spacing={1} padding={2} border="2px dashed purple">
      <Button variant="contained" color="primary">
        Button primary
      </Button>
      <Button variant="contained" color="primary" startIcon={<SettingsIcon />}>
        Button with icon
      </Button>
      <Button variant="contained" color="secondary">
        Button secondary
      </Button>
      <Button variant="outlined" color="primary">
        Outlined Button primary
      </Button>
      <Button variant="outlined" color="error">
        Outlined Button error
      </Button>
      <Button variant="text" color="primary">
        Text Button primary
      </Button>
      <Button variant="contained" color="primary" size="small">
        Small Button primary
      </Button>
      <Button variant="text" color="primary" size="small">
        Small Text Button primary
      </Button>
    </Stack>
  );
};

const Alerts: React.FC = () => {
  return (
    <Stack spacing={1} padding={2} border="2px dashed purple">
      <Alert severity="info">Ihr Beleg befindet sich aktuell in Prüfung</Alert>
      <Alert severity="warning">Es wurde bereits ein Beleg hochgeladen</Alert>
      <Alert severity="error">Beleg abgelehnt - nicht lesbar</Alert>
      <Alert severity="success">Ihr Beleg wurde erfolgreich geprüft</Alert>
    </Stack>
  );
};

const FormFields: React.FC = () => {
  const { control, setError } = useForm({
    defaultValues: {
      text: '',
      password: 'Password',
      date: new Date(),
      month: new Date(),
      select: '',
      checkbox: true,
      radio: '1',
      chips: ['m1@test.de', 'm2@test.de', 'Chip 3'],
      upload: null,
    },
  });
  setError('password', { type: 'pattern' });

  return (
    <Stack spacing={2} padding={2} border="2px dashed purple">
      <Stack spacing={2} direction="row">
        <Stack spacing={2} width={300}>
          <TextFieldControl
            control={control}
            name="text"
            label="TextField Label"
            placeholder="TextField Placeholder"
            helperText="TextField helper text"
            fullWidth
          />
          <TextFieldControl
            control={control}
            name="password"
            label="TextField Label"
            type="password"
            placeholder="TextField Placeholder"
            rules={{ pattern: /xxxxx/ }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <VisibilityOffIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <DatePickerControl control={control} name="date" label="Date" />
          <DatePickerControl control={control} name="month" label="Month" monthYearPicker showNextPreviousToggle />
          <SelectControl
            control={control}
            name="select"
            label="Select Label"
            fullWidth
            options={[
              { label: <em>No selection</em>, value: '' },
              { label: 'Option 1', value: '1' },
              { label: 'Option 2', value: '2' },
            ]}
          />
          <TextFieldChipsControl control={control} name="chips" label="TextField Chips Label" />
          <SearchInput />
        </Stack>

        <Stack spacing={1.5} width={300}>
          <CheckboxControl control={control} name="checkbox" label="Checkbox Label" />
          <SwitchControl control={control} name="checkbox" label="Switch Label" />
          <RadioControl
            control={control}
            name="radio"
            label="Radio Label"
            options={[
              { label: 'Option 1', value: '1' },
              { label: 'Option 2', value: '2' },
            ]}
          />
          <ToggleButtonGroupControl control={control} name="radio" exclusive fullWidth>
            <ToggleButton color="primary" value="1">
              Option 1
            </ToggleButton>
            <ToggleButton color="primary" value="2">
              Option 2
            </ToggleButton>
          </ToggleButtonGroupControl>
          <UploadControl control={control} name="upload" label="Upload Label" />
          <SelectImageControl control={control} name="upload" label="Select Image Label" sx={{ height: 80 }} />
        </Stack>
      </Stack>
      <React.Suspense fallback="Wird geladen">
        {/* eslint-disable-next-line react/jsx-no-bind */}
        <HtmlEditor initialValue={undefined} onChange={() => void 0} />
      </React.Suspense>
      <Stack direction="row" gap={2} justifyContent="center">
        <Chip color="success" label="chip-success" />
        <Chip color="warning" label="chip-warning" />
        <Chip color="info" label="chip-info" />
        <Chip label="chip-default" />
      </Stack>
    </Stack>
  );
};

export const StylesheetPage: React.FC = () => {
  return (
    <Paper sx={{ padding: 2 }}>
      <Grid container spacing={4}>
        <Grid item>
          <Colors />
        </Grid>
        <Grid item>
          <Fonts />
        </Grid>
        <Grid item>
          <Buttons />
          <Box marginY={2} />
          <Alerts />
        </Grid>
        <Grid item>
          <FormFields />
        </Grid>
      </Grid>
    </Paper>
  );
};
