import CachedIcon from '@mui/icons-material/Cached';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, Divider, IconButton, Stack, Switch, Typography, lighten } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { apis, uploadFileToS3 } from 'probonio-shared-ui/module/api';
import { useTenant } from 'probonio-shared-ui/module/me';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ColorPicker } from '../../component/MobileAppPreview/ColorPicker';
import MobileAppPhone from '../../component/MobileAppPreview/MobileAppPreview';
import { SelectImageControl } from '../../component/form/SelectImageControl';
import { ImageFile } from '../../component/imageFile/ImageFile';
import { SettingsFormPanel } from '../../component/settings/SettingsFormPanel';
import { SettingsFormRow } from '../../component/settings/SettingsFormRow';

export interface FormFields {
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  primaryColorDark: string;
  secondaryColorDark: string;
  backgroundColorDark: string;
  tenantImage?: string | ImageFile;
  tenantDarkImage?: string | ImageFile;
  darkMode?: boolean;
}

type ColorKeys = 'primaryColor' | 'secondaryColor' | 'backgroundColor' | 'primaryColorDark' | 'secondaryColorDark' | 'backgroundColorDark';

const DEFAULT_COLORS: Record<ColorKeys, string> = {
  primaryColor: '#9bcbeb',
  secondaryColor: '#004976',
  backgroundColor: '#edf7ff',
  primaryColorDark: '#b3d7ff',
  secondaryColorDark: '#a0ceff',
  backgroundColorDark: '#1c2329',
};

export const CustomisationSettingsPanel: React.FC = () => {
  const { t } = useTranslation('tenantModule');
  const { tenant, isLoading, invalidateTenant } = useTenant();
  const tenantTheme = useMemo(
    () => ({
      primaryColor: tenant?.primaryColor || DEFAULT_COLORS.primaryColor,
      secondaryColor: tenant?.secondaryColor || DEFAULT_COLORS.secondaryColor,
      backgroundColor: tenant?.backgroundColor || DEFAULT_COLORS.backgroundColor,
      primaryColorDark: tenant?.primaryColorDark || DEFAULT_COLORS.primaryColorDark,
      secondaryColorDark: tenant?.secondaryColorDark || DEFAULT_COLORS.secondaryColorDark,
      backgroundColorDark: tenant?.backgroundColorDark || DEFAULT_COLORS.backgroundColorDark,
    }),
    [tenant],
  );
  const { control, reset, handleSubmit, formState, setValue, getValues } = useForm<FormFields>({
    defaultValues: {
      ...tenantTheme,
      tenantImage: tenant?.imageURL,
      tenantDarkImage: tenant?.imageURLDark,
    },
  });

  const [params, setParams] = useState<URLSearchParams>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleReset = useCallback(() => {
    if (!tenant) {
      return;
    }

    reset({
      ...tenantTheme,
      tenantImage: tenant.imageURL,
      tenantDarkImage: tenant.imageURLDark,
    });
  }, [reset, tenant, tenantTheme]);
  useEffect(handleReset, [handleReset]);

  const handleDefault = useCallback(() => {
    if (!tenant) {
      return;
    }

    const { imageURL } = tenant;
    const { imageURLDark } = tenant;

    Object.entries(DEFAULT_COLORS).forEach(([key, value]) => {
      setValue(key as ColorKeys, value, { shouldDirty: true });
    });

    setValue('tenantImage', imageURL);
    setValue('tenantDarkImage', imageURLDark);
  }, [setValue, tenant]);

  const mutation = useMutation({
    mutationFn: async (values: FormFields) => {
      let imageKey: string | undefined;
      let imageKeyDark: string | undefined;
      setIsSubmitting(true);
      if (values.tenantImage && typeof values.tenantImage !== 'string') {
        const uploadLink = await apis.tenants.createUploadLink({ tenantId: tenant!.id }).then(res => res.data);
        let blob: Blob;
        if (typeof values.tenantImage === 'string') {
          blob = await fetch(values.tenantImage).then(res => res.blob());
        } else {
          blob = await values.tenantImage.getBlob();
        }
        await uploadFileToS3(uploadLink, blob, blob.type);
        imageKey = uploadLink.key;
      }
      if (values.tenantDarkImage && typeof values.tenantDarkImage !== 'string') {
        const uploadLink = await apis.tenants.createUploadLink({ tenantId: tenant!.id }).then(res => res.data);
        let blob: Blob;
        if (typeof values.tenantDarkImage === 'string') {
          blob = await fetch(values.tenantDarkImage).then(res => res.blob());
        } else {
          blob = await values.tenantDarkImage.getBlob();
        }
        await uploadFileToS3(uploadLink, blob, blob.type);
        imageKeyDark = uploadLink.key;
      }
      await apis.tenants.updateTenant({
        tenantId: tenant!.id,
        updateTenantDTO: {
          ...(tenant?.additionalFeatures?.includes('CORPORATE_COLORS')
            ? {
                primaryColor: values.primaryColor,
                secondaryColor: values.secondaryColor,
                backgroundColor: values.backgroundColor,
                primaryColorDark: values.primaryColorDark,
                secondaryColorDark: values.secondaryColorDark,
                backgroundColorDark: values.backgroundColorDark,
              }
            : {}),
          imageKey,
          imageKeyDark,
        },
      });
    },

    onSuccess: () => {
      enqueueSnackbar(t('customisationSettings.successToast'), { variant: 'success' });
      setIsSubmitting(false);
      invalidateTenant();
    },
  });

  const handleSave = useCallback(
    (values: FormFields) => {
      mutation.mutate(values);
    },
    [mutation],
  );

  const generateParams = useCallback(() => {
    const newParams = new URLSearchParams();
    newParams.append('colorMode', isDarkMode ? 'dark' : 'light');
    newParams.append('primary', getValues('primaryColor').replace('#', ''));
    newParams.append('secondary', getValues('secondaryColor').replace('#', ''));
    newParams.append('background', getValues('backgroundColor').replace('#', ''));
    newParams.append('primaryDark', getValues('primaryColorDark').replace('#', ''));
    newParams.append('secondaryDark', getValues('secondaryColorDark').replace('#', ''));
    newParams.append('backgroundDark', getValues('backgroundColorDark').replace('#', ''));
    return newParams;
  }, [isDarkMode, getValues]);

  const setGeneratedParams = useCallback(() => {
    setParams(generateParams());
  }, [generateParams]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDarkMode(prev => !prev);
  }, []);

  useEffect(() => {
    setGeneratedParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDarkMode]);

  useEffect(() => {
    if (tenant) {
      setGeneratedParams();
    }
  }, [isDarkMode, getValues, setGeneratedParams, tenant]);

  const tenantImageForm = (
    <SettingsFormRow
      title={t('customisationSettings.logo.title')}
      info={t('customisationSettings.logo.info')}
      descriptionWidth={6}
      settingsWidth={tenant?.additionalFeatures?.includes('CORPORATE_COLORS') ? 6 : 4}
      hideDivider
    >
      <SelectImageControl
        control={control}
        name="tenantImage"
        label={t('customisationSettings.uploadLogo')}
        disabled={isLoading}
        sx={{ height: 80, mb: 2 }}
        maxSize={800}
        tooltip={t('customisationSettings.changeImageTooltip')}
        dataTestId="upload-logo-button"
      />
      <SelectImageControl
        control={control}
        name="tenantDarkImage"
        label={t('customisationSettings.uploadLogoDark')}
        disabled={isLoading}
        sx={{
          height: 80,
          backgroundColor: getValues('backgroundColorDark'),
          '&:hover': { backgroundColor: lighten(getValues('backgroundColorDark'), 0.2) },
        }}
        maxSize={800}
        tooltip={t('customisationSettings.changeImageTooltip')}
      />
    </SettingsFormRow>
  );

  return (
    <SettingsFormPanel
      title={t('customisationSettings.title')}
      info={t('customisationSettings.info')}
      isDirty={formState.isDirty}
      isLoading={isLoading || mutation.isPending}
      onSubmit={handleSubmit(handleSave)}
      onReset={handleReset}
    >
      <Divider sx={{ marginY: 3 }} />
      {tenant?.additionalFeatures?.includes('CORPORATE_COLORS') ? (
        <>
          <Box display="flex" flexDirection="row">
            <Box mt={1}>
              {tenantImageForm}
              <SettingsFormRow
                title={t('customisationSettings.guide.backgroundColor.title')}
                info={t('customisationSettings.guide.backgroundColor.info')}
                descriptionWidth={6}
                settingsWidth={6}
              >
                <Box display="flex" flexDirection="row" ml={4} alignItems="center" gap={3}>
                  <Box display="flex" flexDirection="column" gap={3}>
                    <Typography variant="h3" alignSelf="center">
                      {t('customisationSettings.guide.lightTheme')}
                    </Typography>
                    <ColorPicker name="backgroundColor" control={control} />
                  </Box>
                  <Box display="flex" flexDirection="column" gap={3}>
                    <Typography variant="h3" alignSelf="center">
                      {t('customisationSettings.guide.darkTheme')}
                    </Typography>
                    <ColorPicker name="backgroundColorDark" control={control} />
                  </Box>
                </Box>
              </SettingsFormRow>
              <SettingsFormRow
                title={t('customisationSettings.guide.accentColor.title')}
                info={t('customisationSettings.guide.accentColor.info')}
                descriptionWidth={6}
                settingsWidth={6}
              >
                <Box display="flex" flexDirection="row" ml={4} alignItems="center" gap={3}>
                  <ColorPicker name="primaryColor" control={control} />
                  <ColorPicker name="primaryColorDark" control={control} />
                </Box>
              </SettingsFormRow>
              <SettingsFormRow
                title={t('customisationSettings.guide.buttonColor.title')}
                info={t('customisationSettings.guide.buttonColor.info')}
                descriptionWidth={6}
                settingsWidth={6}
              >
                <Box display="flex" flexDirection="row" ml={4} alignItems="center" gap={3}>
                  <ColorPicker name="secondaryColor" control={control} />
                  <ColorPicker name="secondaryColorDark" control={control} />
                </Box>
              </SettingsFormRow>
            </Box>
            <Box style={{ justifyContent: 'center', textAlignLast: 'center', textAlign: 'center' }} display="flex" flexDirection="row">
              <Box>
                <Stack direction="row" justifyContent="center" spacing={2}>
                  <IconButton sx={{ color: 'text.secondary' }} title={t('customisationSettings.getDefault')} onClick={handleDefault}>
                    <UndoIcon />
                  </IconButton>
                  <Stack direction="row" alignItems="center">
                    <Typography variant="h3">{t('customisationSettings.guide.lightTheme')}</Typography>
                    <Switch name="darkMode" checked={isDarkMode} onChange={handleChange} />

                    <Typography variant="h3">{t('customisationSettings.guide.darkTheme')}</Typography>
                  </Stack>
                  <IconButton
                    sx={{ color: 'text.secondary' }}
                    title={t('customisationSettings.generatePreview')}
                    onClick={setGeneratedParams}
                  >
                    <CachedIcon />
                  </IconButton>
                </Stack>
                {!isSubmitting && <MobileAppPhone params={params} />}
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        tenantImageForm
      )}
    </SettingsFormPanel>
  );
};
