import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Box, Typography, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { apis } from 'probonio-shared-ui/module/api';
import { useTenant, useTenantID } from 'probonio-shared-ui/module/me';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BasicDialog, DialogState } from '../../../component/dialog';
import { DialogLoadingButton } from '../../../component/dialog/DialogLoadingButton';
import { PROBONIO_MAIL } from '../../../constants';
import infoboxFirmenfitness from './Infobox.png';

interface Props {
  dialogState: DialogState;
}

export const SendMailDialog: React.FC<Props> = ({ dialogState }) => {
  const { t } = useTranslation('homePage');
  const theme = useTheme();
  const getTenantId = useTenantID();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant, invalidateTenant } = useTenant();

  const mailWasSent = tenant?.mailIsSent?.includes('corporate-fitness');

  const sendSalesMail = useMutation({
    mutationFn: async () => {
      await apis.tenants.sendSalesMail({
        tenantId: getTenantId(),
        tenantSalesMailDTO: { mailType: 'corporate-fitness' },
      });
    },

    onSuccess: async () => {
      invalidateTenant();
      await enqueueSnackbar(t('customBenefitsModule:benefits.URBAN_SPORTS_CLUB.successRequest'), { variant: 'success' });
    },
  });

  const handleDialog = useCallback(() => {
    sendSalesMail.mutate();
  }, [sendSalesMail]);

  return !mailWasSent ? (
    <BasicDialog
      dialogState={dialogState}
      title={
        <Box textAlign="center">
          <Trans i18nKey="customBenefitsModule:benefits.URBAN_SPORTS_CLUB.unlockTitle" />
        </Box>
      }
      content={
        <>
          <img className="infoboxFirmenfitness" src={infoboxFirmenfitness} width="560px" style={{ display: 'block' }} />
          <Box paddingTop={2} paddingBottom={2}>
            <Typography variant="body1" textAlign="center" color="text.secondary" component="div">
              <Trans i18nKey="customBenefitsModule:benefits.URBAN_SPORTS_CLUB.unlockDescription1" />
            </Typography>
          </Box>
          <Box
            padding={2}
            borderRadius={2}
            sx={{ backgroundColor: theme.palette.background.default, '& p': { marginTop: 0 }, '& ol': { marginBottom: 0 } }}
          >
            <Typography variant="body1" color="text.secondary" component="div">
              <Trans i18nKey="customBenefitsModule:benefits.URBAN_SPORTS_CLUB.unlockExplanation" />
            </Typography>
          </Box>
          <Box paddingTop={2}>
            <Typography variant="body1" color="text.secondary" component="div">
              <Trans i18nKey="customBenefitsModule:benefits.URBAN_SPORTS_CLUB.unlockDescription2" />
            </Typography>
          </Box>
        </>
      }
      actions={
        <DialogLoadingButton variant="text" onClick={handleDialog} component="div">
          {t('customBenefitsModule:benefits.URBAN_SPORTS_CLUB.unlockUSC')}
        </DialogLoadingButton>
      }
    />
  ) : (
    <BasicDialog
      dialogState={dialogState}
      title={
        <Box textAlign="center">
          <Trans i18nKey="customBenefitsModule:benefits.URBAN_SPORTS_CLUB.successTitle" component="div" />
        </Box>
      }
      content={
        <Typography align="center" variant="body1" color="text.secondary">
          <Trans i18nKey="customBenefitsModule:benefits.URBAN_SPORTS_CLUB.successText" component="div" />
        </Typography>
      }
      actions={
        <DialogLoadingButton variant="text" href={`mailto:${PROBONIO_MAIL}`} startIcon={<HelpOutlineOutlinedIcon />}>
          {t('homePage:support.buttonText2')}
        </DialogLoadingButton>
      }
    />
  );
};
